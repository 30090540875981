import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const AddSymbolButton = ({ onAddSymbol, existingSymbols, isLoggedIn, className = '', children, title, 'data-tooltip': tooltip, usePortal, ...props }) => {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [localWatchlist, setLocalWatchlist] = useState(new Set(existingSymbols));
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    resetState();
  };

  const resetState = () => {
    setQuery('');
    setCompanies([]);
    setError('');
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSearch = async () => {
    if (query.length < 2) {
      setCompanies([]);
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://watchlist-lookup.drisw.workers.dev/?query=${query}`);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setError('Failed to fetch companies. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectCompany = async (company) => {
    if (!company || !company.symbol) {
      setError('Invalid company data. Please try again.');
      setStatusType('error');
      return;
    }

    const symbol = company.symbol.trim();
    if (!symbol) {
      setError('Invalid symbol. Please try again.');
      setStatusType('error');
      return;
    }

    try {
      setIsLoading(true);
      await onAddSymbol(symbol);
      setLocalWatchlist(prev => new Set(prev).add(symbol));
      setStatusMessage(`${symbol} added to watch list successfully.`);
      setStatusType('success');
    } catch (error) {
      console.error('Error adding company to watchlist:', error);
      setError(error.message || `Failed to add ${symbol} to watchlist. Please try again.`);
      setStatusType('error');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setError('');
        setStatusMessage('');
      }, 5000);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (query.length >= 2) handleSearch();
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Update localWatchlist whenever existingSymbols changes
  useEffect(() => {
    setLocalWatchlist(new Set(existingSymbols));
  }, [existingSymbols]);

  return (
    <>
      <button
        onClick={handleShow}
        className={className}
        data-tooltip={tooltip}
        {...props}
      >
        {children}
      </button>
      {show && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={dropdownRef} className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Add To Watch List</h2>
              <button onClick={handleClose} className="text-black text-2xl leading-none">&times;</button>
            </div>
            {!isLoggedIn && (
              <div className="mb-4 flex items-start space-x-2 text-blue-600">
                <FontAwesomeIcon icon={faCircleInfo} className="mt-0.5 text-blue-500" />
                <p className="text-xs font-medium">
                  You are not logged in. Symbols will be saved locally only.
                </p>
              </div>
            )}
            <div>
              <input
                ref={inputRef}
                type="text"
                placeholder="Search for a company..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              />
              {query.length > 0 && query.length < 2 && (
                <p className="mt-2 text-yellow-600">Please enter at least 2 characters</p>
              )}
              {isLoading && <p className="mt-2">Loading...</p>}
              {error && <p className="mt-2 text-red-500">{error}</p>}
              {companies.length > 0 && (
                <ul className="mt-4 max-h-60 overflow-y-auto">
                  {companies.map((company) => {
                    const isExisting = localWatchlist.has(company.symbol);
                    const logoUrl = company.logo_url || (company.icon_data 
                      ? `data:image/png;base64,${company.icon_data}`
                      : null);

                    return (
                      <li
                        key={company.symbol}
                        className={`flex items-center justify-between p-2 ${
                          isExisting 
                            ? 'text-gray-400 bg-gray-100'
                            : 'text-gray-900 cursor-pointer hover:bg-gray-50'
                        }`}
                        onClick={() => !isExisting && handleSelectCompany(company)}
                      >
                        <div className="flex items-center flex-grow">
                          {logoUrl ? (
                            <img
                              src={logoUrl}
                              alt={`${company.companyName} logo`}
                              className={`w-6 h-6 mr-2 object-contain ${isExisting ? 'opacity-50' : ''}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(company.companyName)}&background=random`;
                              }}
                            />
                          ) : (
                            <div className={`w-6 h-6 mr-2 bg-gray-200 flex items-center justify-center rounded-full ${isExisting ? 'opacity-50' : ''}`}>
                              {company.companyName.charAt(0)}
                            </div>
                          )}
                          <span>{company.companyName} ({company.symbol})</span>
                        </div>
                        {isExisting && (
                          <span className="ml-2 px-2 py-1 bg-blue-500 text-white text-xs font-semibold rounded-full whitespace-nowrap">
                            In Watch List
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {statusMessage && (
              <div className={`mt-2 ${statusType === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                {statusMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddSymbolButton;
