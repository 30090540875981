import React from 'react';

const Tooltip = ({ children, text }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute left-0 top-full mt-1 hidden group-hover:block z-[60] w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg">
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
