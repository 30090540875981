import React, { useState, useMemo } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import CONFIG from '../config/config';
import { Tooltip } from 'react-tooltip';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
} from 'recharts';
// You can add other icons, e.g. FiAlertTriangle, FiTrendingUp, etc., if needed
import DisclaimerMessage from './DisclaimerMessage';
import '../styles/BondInsights.css'; // Similar to your MacroScenarios.css

// Define tooltip content for each curve type
const CURVE_DESCRIPTIONS = {
  yield: "The yield curve shows annual return rates for bonds of equal credit quality but different maturities. It's the most commonly referenced curve for market analysis.",
  zero: "The zero-coupon yield curve represents yields on theoretical bonds that make no interim payments, useful for pure time-value analysis.",
  discount: "The discount curve shows the present value factors used to discount future cash flows, derived from market prices of treasury securities."
};

// Custom Legend component with tooltips
const CustomLegend = ({ payload }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', padding: '0.5rem' }}>
      {payload.map((entry, index) => (
        <div
          key={`legend-${index}`}
          data-tooltip-id="curve-tooltip"
          data-tooltip-content={CURVE_DESCRIPTIONS[entry.value.toLowerCase()]}
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '0.25rem',
            cursor: 'help' 
          }}
        >
          <div style={{ 
            width: '12px', 
            height: '12px', 
            backgroundColor: entry.color 
          }} />
          <span>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

// ---- COMPACT VIEW (folded) ----
const CompactBondInsights = ({ yieldCurveData }) => {
  // For simplicity, we'll just show short vs. long yields
  const shortTerm = yieldCurveData.find(d => d.maturity === '3M');
  const longTerm = yieldCurveData.find(d => d.maturity === '10Y');

  // Compute a quick "slope"
  const slope = shortTerm && longTerm
    ? (longTerm.yield - shortTerm.yield).toFixed(2)
    : null;

  // Now add slope color-coding
  const slopeNum = slope ? parseFloat(slope) : 0;
  const slopeClass = getSlopeClassName(slopeNum);

  return (
    <div className="compact-bond-insights-container">
      <div className="bond-insights-pill">
        <span className="label">Short vs. Long: </span>
        {shortTerm
          ? `3M = ${shortTerm.yield.toFixed(2)}%`
          : 'N/A'}{' '}
        |{' '}
        {longTerm
          ? `10Y = ${longTerm.yield.toFixed(2)}%`
          : 'N/A'}
      </div>
      <div
        className={`bond-insights-pill ${slopeClass}`}
      >
        <span className="label">Curve Slope (3M–10Y): </span>
        {slope ? `${slope}%` : 'N/A'}
      </div>
    </div>
  );
};

// Add a helper function to interpret the yield curve
function interpretYieldCurves(chartData) {
  // Find 3M and 10Y points
  const shortTerm = chartData.find(d => d.maturity === '3M');
  const longTerm = chartData.find(d => d.maturity === '10Y');

  // If missing data, return a generic note
  if (!shortTerm || !longTerm) {
    return "Insufficient data to compute slope (need 3M and 10Y).";
  }

  // Compute the slope
  const slope = longTerm.yield - shortTerm.yield;

  // Return a short text interpretation
  if (slope < -0.1) {
    return "Yield curve appears inverted, which can sometimes signal recessionary concerns.";
  } else if (slope < 0.1) {
    return "Yield curve is relatively flat, suggesting a transitional environment.";
  } else {
    return "Yield curve is positively sloped, often signaling stable or growing economic conditions.";
  }
}

// ───────────────────────────────────────────────────────────────────────────
// Step A: Helper to compute Pearson correlation for yield vs. zero
//         (You can reuse or extend for discount as well.)
// ───────────────────────────────────────────────────────────────────────────
function computePearsonCorrelation(xArray, yArray) {
  if (xArray.length !== yArray.length || xArray.length === 0) {
    return null;
  }
  const n = xArray.length;
  const meanX = xArray.reduce((sum, val) => sum + val, 0) / n;
  const meanY = yArray.reduce((sum, val) => sum + val, 0) / n;
  const numerator = xArray.reduce((acc, x, i) => {
    return acc + (x - meanX) * (yArray[i] - meanY);
  }, 0);
  const denomX = Math.sqrt(xArray.reduce((acc, x) => acc + (x - meanX) ** 2, 0));
  const denomY = Math.sqrt(yArray.reduce((acc, y) => acc + (y - meanY) ** 2, 0));
  const denominator = denomX * denomY;
  return denominator === 0 ? null : numerator / denominator;
}

// Next, interpret correlation to produce a short "trend/signal" message
function interpretYieldZeroCorrelation(chartData) {
  const dataPoints = chartData
    .filter(d => typeof d.yield === 'number' && typeof d.zero === 'number')
    .sort((a, b) => parseMaturity(a.maturity) - parseMaturity(b.maturity));

  const yieldValues = dataPoints.map(d => d.yield);
  const zeroValues = dataPoints.map(d => d.zero);

  if (yieldValues.length < 2) {
    return "Not enough data to evaluate the relationship between yield and zero curves.";
  }

  const r = computePearsonCorrelation(yieldValues, zeroValues) ?? 0;

  // Provide a simple set of cutoffs
  let signal, meaning;
  if (r > 0.85) {
    signal = "The Yield and Zero curves are strongly correlated.";
    meaning = "They move in tandem across maturities, indicating that coupon-bearing yields and zero-coupon yields reflect very similar interest rate expectations at each maturity. This alignment often implies consistent market outlooks across different bond types.";
  } else if (r > 0.4) {
    signal = "The Yield and Zero curves have moderate correlation.";
    meaning = "They partially align across maturities but exhibit noticeable differences in certain spots. This suggests that while many interest rate expectations are similar for coupon-bearing and zero-coupon bonds, local factors or coupon effects can create divergences in specific parts of the curve.";
  } else {
    signal = "The Yield and Zero curves have weak correlation.";
    meaning = "They differ significantly in shape across one or more maturities. Such divergence may result from unique coupon effects, liquidity conditions, or market segmentation, causing these two curves to price interest rate risk differently.";
  }

  return `Correlation: ${r.toFixed(2)}. ${signal} ${meaning}`;
}

// Helper to parse maturity string (e.g. "3M", "1Y") into numeric months
function parseMaturity(m) {
  if (!m) return 0;
  const unit = m.slice(-1).toUpperCase(); // "M" or "Y"
  const val = parseInt(m.slice(0, -1), 10) || 0;
  return (unit === 'Y') ? val * 12 : val;
}

// Now add slope color-coding in CompactBondInsights
// First, define a small helper inside (or outside) CompactBondInsights:
function getSlopeClassName(slopeValue) {
  if (slopeValue < 0) return 'negative-slope';
  if (slopeValue < 0.5) return 'flat-slope';
  return 'positive-slope';
}

// ---- MAIN COMPONENT ----
const BondInsightsSection = ({
  isLoading = false,
  error = null,
  lastUpdated = 'N/A',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // ─────────────────────────────────────────────────────────────────────────
  // STEP 1 fetch from the new /curves-data endpoint
  // We'll just log the results for now.
  // ─────────────────────────────────────────────────────────────────────────
  const {
    data: curvesData,
    isLoading: curvesLoading,
    error: curvesError,
  } = useQuery({
    queryKey: ['curvesData'],
    queryFn: async () => {
      // Use the new config endpoint
      const resp = await fetch(CONFIG.API_URLS.CURVES_DATA);
      if (!resp.ok) {
        throw new Error(`Failed to fetch curves data: ${resp.status}`);
      }
      return resp.json();
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 5 * 60 * 1000, // Re-fetch every 5 minutes
  });

  console.log('curvesData:', curvesData, curvesError);

  // ─────────────────────────────────────────────────────────────────────
  // STEP 2: Build chart data from curvesData
  // We'll parse yield_curve, zero_curve, and discount_curve into a single array.
  const chartData = useMemo(() => {
    if (!curvesData || curvesError) return [];

    const { yield_curve = {}, zero_curve = {}, discount_curve = {} } = curvesData;

    // Collect all maturities from all curve objects
    const uniqueMaturities = new Set([
      ...Object.keys(yield_curve),
      ...Object.keys(zero_curve),
      ...Object.keys(discount_curve),
    ]);

    // Build array of { maturity, yield, zero, discount }
    return [...uniqueMaturities].map(m => ({
      maturity: m,
      yield: yield_curve[m] ?? null,
      zero: zero_curve[m] ?? null,
      discount: discount_curve[m] ?? null,
    }));
  }, [curvesData, curvesError]);

  // Generate our dynamic interpretation text
  const dynamicInterpretation = useMemo(() => {
    return interpretYieldCurves(chartData);
  }, [chartData]);

  // ─────────────────────────────────────────────────────────────────
  // Step B: Correlation-based interpretation for yield vs. zero
  // ─────────────────────────────────────────────────────────────────
  const correlationInterpretation = useMemo(() => {
    return interpretYieldZeroCorrelation(chartData);
  }, [chartData]);

  // Handle error or loading states
  if (error) {
    return <div className="error-message">Error loading bond insights: {error.message}</div>;
  }
  if (isLoading || curvesLoading) {
    return <div className="loading">Loading Bond &amp; Interest Rate Insights...</div>;
  }

  return (
    <div className="bond-insights-section">
      {/* Section Header (Folded View) */}
      <div className="section-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="header-content">
          <h3>Bond &amp; Interest Rate Insights</h3>
          {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
        </div>
        {/* Compact summary preview uses real yield curve now */}
        <CompactBondInsights yieldCurveData={chartData} />
      </div>

      {/* Expanded Content */}
      {isExpanded && (
        <div className="insights-content">
          {/* ──────────────────────────────────────────────────────────────────
             STEP 2(a): Render single chart with yield, zero, discount lines
             We'll show it *above* the old BondInsightsDetail for clarity.
          ────────────────────────────────────────────────────────────────── */}
          <div style={{ display: 'flex', gap: '1rem' }}>
            {/* Chart Container */}
            <div style={{ flex: '2', height: 300 }}>
              <LineChart data={chartData} width={600} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="maturity" />
                <YAxis />
                <RechartsTooltip />
                <Legend 
                  content={<CustomLegend />}
                  wrapperStyle={{ cursor: 'default' }}
                />
                <Line 
                  type="monotone" 
                  dataKey="yield" 
                  stroke="#8884d8" 
                  dot={false} 
                  strokeWidth={3} 
                  name="Yield" 
                />
                <Line 
                  type="monotone" 
                  dataKey="zero" 
                  stroke="#82ca9d" 
                  dot={false} 
                  strokeOpacity={0.8} 
                  name="Zero" 
                />
                <Line 
                  type="monotone" 
                  dataKey="discount" 
                  stroke="#ffc658" 
                  dot={false} 
                  strokeOpacity={0.8} 
                  name="Discount" 
                />
              </LineChart>
            </div>

            {/* Interpretation Panel */}
            <div style={{ flex: '1' }} className="interpretation-panel">
              <h4>Interpretation</h4>
              {curvesData?.interpretation ? (
                <p>{curvesData.interpretation}</p>
              ) : (
                <>
                  <p>
                    <strong>3M-10Y Slope Analysis:</strong> {dynamicInterpretation}
                  </p>
                  <p style={{ marginTop: '1rem' }}>
                    <strong>Yield vs. Zero Correlation:</strong> {correlationInterpretation}
                  </p>
                </>
              )}
            </div>
          </div>

          {/* Disclaimer (optional) 
          <DisclaimerMessage 
            message="Bond insights are approximate and for illustrative purposes. Real market data may vary."
            lastUpdated={curvesData?.timestamp ?? null}
            className="mt-4"
          />*/}
        </div>
      )}
      <Tooltip 
        id="curve-tooltip"
        place="top"
        className="z-[9999]"
      />
    </div>
  );
};

export default BondInsightsSection;