import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import { FiChevronDown, FiChevronUp, FiPause, FiPlay, FiClock, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { authenticatedFetch, publicFetch } from '../utils/authenticatedFetch';
import CONFIG from '../config/config';

const NewsRow = ({ 
  onAddSymbol, 
  userSettings = {}, 
  onSettingsChange,
  news = []
}) => {
  //console.log('NewsRow received settings:', userSettings);
  
  const { newsCarousel = {} } = userSettings;
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(newsCarousel.isPaused || false);
  const [speed, setSpeed] = useState(newsCarousel.speed || 8000);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tickerRefs = useRef(new Map());
  const [activeTooltipTicker, setActiveTooltipTicker] = useState(null);
  const [viewMode, setViewMode] = useState('minimal');

  // Modified carousel rotation effect
  useEffect(() => {
    if (!isExpanded && news.length > 0 && !isPaused) {
      const timer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % news.length);
      }, speed);
      return () => clearInterval(timer);
    }
  }, [isExpanded, news.length, isPaused, speed]);

  const formatPublishedDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy, h:mm a');
    } catch (e) {
      return dateString;
    }
  };

  const handleSpeedChange = useCallback((newSpeed) => {
    setSpeed(newSpeed);
    onSettingsChange?.('newsCarousel', {
      ...newsCarousel,
      speed: newSpeed
    });
  }, [onSettingsChange, newsCarousel]);

  const togglePlayPause = useCallback(() => {
    const newPausedState = !isPaused;
    setIsPaused(newPausedState);
    onSettingsChange?.('newsCarousel', {
      ...newsCarousel,
      isPaused: newPausedState
    });
  }, [isPaused, onSettingsChange, newsCarousel]);

  const goToNextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % news.length);
  }, [news.length]);

  const goToPrevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + news.length) % news.length);
  }, [news.length]);

  const handleTickerClick = (e, ticker) => {
    e.preventDefault(); // Prevent the news article link from activating
    e.stopPropagation(); // Prevent event bubbling
    
    // Clean the ticker by removing $ or # symbols
    const cleanTicker = ticker.replace(/[$#]/g, '');
    onAddSymbol(cleanTicker);
  };

  const updateTooltipPosition = (ticker) => {
    const ref = tickerRefs.current.get(ticker);
    if (ref) {
      const rect = ref.getBoundingClientRect();
      setTooltipStyle({
        left: `${rect.left + (rect.width / 2)}px`,
        top: `${rect.top - 32}px`,
        opacity: 1,
        visibility: 'visible',
        transform: 'translateX(-50%)',
      });
      setActiveTooltipTicker(ticker);
    }
  };

  const handleMouseLeave = () => {
    setTooltipStyle(prev => ({
      ...prev,
      opacity: 0,
      visibility: 'hidden'
    }));
    setActiveTooltipTicker(null);
  };

  // Update states when userSettings change
  useEffect(() => {
    if (userSettings.newsCarousel) {
      if (userSettings.newsCarousel.speed !== undefined) {
        setSpeed(userSettings.newsCarousel.speed);
      }
      if (userSettings.newsCarousel.isPaused !== undefined) {
        setIsPaused(userSettings.newsCarousel.isPaused);
      }
    }
  }, [userSettings]);

  const renderMinimalView = () => {
    if (!news.length) return null;
    const article = news[currentSlide];
    
    return (
      <div className="news-minimal-view">
        {article.imageUrl && (
          <div className="news-minimal-thumbnail">
            <img
              src={article.imageUrl}
              alt=""
              className="news-minimal-image"
            />
          </div>
        )}
        <a 
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          className="news-minimal-title"
        >
          {article.title}
        </a>
      </div>
    );
  };

  if (!news.length) {
    return <div className="animate-pulse h-40 bg-gray-100 rounded-lg"></div>;
  }

  return (
    <div className="news-section">
      <div className="news-header">
        <div className="news-header-content">
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-semibold text-gray-900">News</h2>
            {viewMode === 'minimal' && renderMinimalView()}
          </div>
        </div>
        
        <button 
          onClick={() => setViewMode(viewMode === 'minimal' ? 'normal' : 'minimal')}
          className="news-toggle-button"
        >
          {viewMode === 'minimal' ? (
            <>Show More <FiChevronDown className="w-4 h-4" /></>
          ) : (
            <>Show Less <FiChevronUp className="w-4 h-4" /></>
          )}
        </button>
      </div>

      <div className={`news-content-transition ${viewMode === 'minimal' ? 'minimal' : 'expanded'}`}>
        {viewMode !== 'minimal' && (
          <div className="news-carousel">
            {news.length > 0 && (
              <>
                <div className="news-carousel-content">
                  <a 
                    href={news[currentSlide].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="news-thumbnail hover:opacity-80 transition-opacity"
                  >
                    <img
                      src={news[currentSlide].imageUrl}
                      alt={news[currentSlide].title}
                    />
                  </a>
                  <div className="flex-grow">
                    <div className="news-meta">
                      <span className="news-source">
                        {news[currentSlide].source}
                      </span>
                      <span className="news-date">
                        {formatPublishedDate(news[currentSlide].publishedAt)}
                      </span>
                    </div>
                    <a 
                      href={news[currentSlide].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block hover:text-blue-600 transition-colors"
                    >
                      <h3 className="news-title">{news[currentSlide].title}</h3>
                    </a>
                    <p className="news-description">
                      {news[currentSlide].description}
                    </p>
                    {news[currentSlide].tickers && (
                      <div className="news-tickers">
                        {news[currentSlide].tickers.map((ticker, idx) => (
                          <span
                            key={idx}
                            className="ticker-tooltip-container"
                          >
                            <span
                              ref={(el) => tickerRefs.current.set(ticker, el)}
                              className="news-ticker hover:bg-blue-200 cursor-pointer"
                              onClick={(e) => handleTickerClick(e, ticker)}
                              onMouseEnter={() => updateTooltipPosition(ticker)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {ticker}
                            </span>
                            {activeTooltipTicker === ticker && (
                              <span 
                                className="earnings-estimate-tooltip" 
                                style={tooltipStyle}
                              >
                                Add {ticker} to watchlist
                              </span>
                            )}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="carousel-controls">
                  <div className="carousel-controls-left">
                    <button
                      onClick={togglePlayPause}
                      className="control-button"
                      title={isPaused ? "Play" : "Pause"}
                    >
                      {isPaused ? <FiPlay /> : <FiPause />}
                    </button>
                    
                    <div className="navigation-controls">
                      <button
                        onClick={goToPrevSlide}
                        className="control-button"
                        title="Previous"
                      >
                        <FiChevronLeft />
                      </button>
                      <button
                        onClick={goToNextSlide}
                        className="control-button"
                        title="Next"
                      >
                        <FiChevronRight />
                      </button>
                    </div>
                    
                    <div className="speed-controls">
                      <FiClock className="speed-icon" />
                      <select
                        value={speed}
                        onChange={(e) => handleSpeedChange(Number(e.target.value))}
                        className="speed-select"
                      >
                        <option value={4000}>4s</option>
                        <option value={8000}>8s</option>
                        <option value={12000}>12s</option>
                        <option value={16000}>16s</option>
                      </select>
                    </div>
                  </div>

                  <div className="carousel-dots">
                    {news.map((_, idx) => (
                      <button
                        key={idx}
                        onClick={() => setCurrentSlide(idx)}
                        className={`carousel-dot ${currentSlide === idx ? 'active' : ''}`}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsRow; 