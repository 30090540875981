import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import Modal from 'react-modal';
import { Menu, Transition } from '@headlessui/react';
import GoogleLoginButton from './GoogleLoginButton';
import StatusHeader from './StatusHeader';
import CONFIG from '../config/config';
import './Header.css';
import PropTypes from 'prop-types';
import { isTokenExpired } from '../utils/auth';

Modal.setAppElement('#root');

// Add this near the top of the file, outside the component
const MOBILE_WARNING_DISMISSED_KEY = 'mobileWarningDismissed';

const Header = ({
  onLoginSuccess,
  onLogout,
  loggedInUser,
  trialDaysLeft,
  isSubscribed,
  statusMessage,
  statusType,
  appStatus,
  onProfileClick,
  onSubscriptionClick,
  onSettingsSave
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(!loggedInUser);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showDeleteSubmenu, setShowDeleteSubmenu] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const toggleDropdown = () => {
    console.log('Toggling dropdown');
    setIsDropdownOpen(prev => {
      console.log('New dropdown state:', !prev);
      return !prev;
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!loggedInUser && token && !isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      if (decodedToken?.email) {
        onLoginSuccess({
          email: decodedToken.email,
          name: decodedToken.name || decodedToken.email.split('@')[0]
        });
      }
    }
  }, [onLoginSuccess, loggedInUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add this useEffect to detect mobile devices
  useEffect(() => {
    const checkMobileDevice = () => {
      const isMobile = window.innerWidth <= 768;
      const warningDismissed = localStorage.getItem(MOBILE_WARNING_DISMISSED_KEY);
      setShowMobileWarning(isMobile && !warningDismissed);
    };

    checkMobileDevice();
    window.addEventListener('resize', checkMobileDevice);

    return () => {
      window.removeEventListener('resize', checkMobileDevice);
    };
  }, []);

  const getUserInitials = (user) => {
    if (!user) return '';
    const displayName = typeof user === 'string' ? user : (user.name || user.email);
    return displayName
      .split(' ')
      .map(name => name[0].toUpperCase())
      .join('');
  };

  const handleLoginSuccess = async (response) => {
    if (response?.credential) {
      const decodedToken = jwtDecode(response.credential);
      if (decodedToken?.email) {
        try {
          // Store Google token for fallback
          localStorage.setItem('googleToken', response.credential);

          // Get our own token
          const tokenResponse = await fetch('https://api.1stprinciples.pro/auth/generateToken', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: decodedToken.email })
          });

          if (tokenResponse.ok) {
            const { token } = await tokenResponse.json();
            localStorage.setItem('authToken', token);
          }

          // Continue with login
          onLoginSuccess({
            email: decodedToken.email,
            name: decodedToken.name || decodedToken.email.split('@')[0]
          });
          setShowLoginButton(false);
        } catch (error) {
          console.error('New auth setup failed, using Google auth:', error);
        }
      }
    }
  };

  const handleLogout = () => {
    // Clear both tokens
    localStorage.removeItem('authToken');
    localStorage.removeItem('googleToken');
    onLogout();
    setShowLoginButton(true);
    setIsDropdownOpen(false);
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
    setShowLoginButton(true);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubscribe = () => {
    closeModal();
  };

  const handleSubscriptionClick = () => {
    window.open('https://billing.stripe.com/p/login/00g9Er4TpfdqdHOcMM?prefilled_promo_code=VfjF0RPi', '_blank');
  };

  const handleDeleteProfile = async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        const response = await fetch(`${CONFIG.API_URL}/api/user-data?userId=${encodeURIComponent(loggedInUser.email)}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.ok) {
          handleLogout();
          alert("Your profile has been deleted successfully.");
        } else {
          throw new Error('Failed to delete profile');
        }
      } catch (error) {
        console.error('Error deleting profile:', error);
        alert("An error occurred while deleting your profile. Please try again later.");
      }
    }
  };

  const handleDismissMobileWarning = useCallback(() => {
    localStorage.setItem(MOBILE_WARNING_DISMISSED_KEY, 'true');
    setShowMobileWarning(false);
  }, []);

  return (
    <>
      <Modal
        isOpen={showMobileWarning}
        onRequestClose={handleDismissMobileWarning}
        contentLabel="Mobile Device Warning"
        className="mobile-warning-modal"
        overlayClassName="mobile-warning-overlay"
      >
        <div className="mobile-warning-content">
          <h2>Desktop Experience Recommended</h2>
          <p>This application is optimized exclusively for desktop use at the moment. We are working on a mobile version.</p>
          <button 
            onClick={handleDismissMobileWarning}
            className="mobile-warning-button"
          >
            I Understand
          </button>
        </div>
      </Modal>
      {appStatus && (
        <div 
          className={`status-bar ${appStatus.type}`}
        >
          {appStatus.message}
        </div>
      )}
      <div className="header">
        <div className="logo">
          <img src="./quantape.png" alt="First Principles Analytics Logo" className="logo-image" />
          <div className="title-container">
            <h1>QuantApe Smart Screener</h1>
            <h2>For the Investor Who Refuses to Be Ordinary</h2>
          </div>
        </div>
        <div className="right-section">
          {!isSubscribed && trialDaysLeft > 0 && (
            <div className="trial-banner" onClick={openModal}>
              <span>Subscribe</span>
            </div>
          )}
          {loggedInUser ? (
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="user-icon-button">
                <div className="user-icon">
                  <span className="user-initials">{getUserInitials(loggedInUser)}</span>
                </div>
              </Menu.Button>
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={onProfileClick}
                          className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FontAwesomeIcon icon={faUser} className="mr-2" />
                          Profile
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <GoogleLoginButton
              onLoginSuccess={handleLoginSuccess}
              onLoginFailure={handleLoginFailure}
            />
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Subscription Plans"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <button onClick={closeModal} className="close-modal-button">X</button>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table
            pricing-table-id="prctbl_1PRjBcK2h01IjPAi0ihULu1L"
            publishable-key="pk_live_01aAutTYAQHsauVPglKbqeln00sTJBIfF2"
            default-promotion-code="VfjF0RPi"
          >
          </stripe-pricing-table>
        </Modal>
      </div>
    </>
  );
};

Header.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  loggedInUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  trialDaysLeft: PropTypes.number,
  isSubscribed: PropTypes.bool,
  statusMessage: PropTypes.string,
  statusType: PropTypes.string,
  appStatus: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string
  }),
  onProfileClick: PropTypes.func.isRequired,
  onSubscriptionClick: PropTypes.func.isRequired,
  onSettingsSave: PropTypes.func.isRequired
};

Header.defaultProps = {
  loggedInUser: null,
  trialDaysLeft: 0,
  isSubscribed: false,
  statusMessage: '',
  statusType: 'info',
  appStatus: null
};

export default Header;
