import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import '../styles/DisclaimerMessage.css';

const DisclaimerMessage = ({ 
  message, 
  lastUpdated = null,
  className = "" 
}) => {
  return (
    <div className={`disclaimer-message ${className}`}>
      <FontAwesomeIcon icon={faTriangleExclamation} />
      <div>
        {message}
        {lastUpdated && (
          <span className="timestamp">
            Last updated: {new Date(lastUpdated).toLocaleDateString()}
          </span>
        )}
      </div>
    </div>
  );
};

export default DisclaimerMessage; 