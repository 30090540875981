import React, { useState } from 'react';
import { publicFetch } from '../utils/authenticatedFetch';
import CONFIG from '../config/config';
import './AIFilter.css';

const AIFilterModal = ({ onClose, onSuccess }) => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) {
      setError('Please enter a description');
      return;
    }

    setIsLoading(true);
    setError('');

    publicFetch(CONFIG.API_URLS.AI_FILTER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt })
    })
    .then(response => response.json())
    .then(result => {
      if (!result.symbols || !Array.isArray(result.symbols)) {
        throw new Error('Invalid response format from AI filter');
      }

      const listId = `ai_${Date.now()}`;
      const newList = {
        id: listId,
        name: result.short_name || 'AI Generated List',
        symbols: result.symbols,
        description: result.criteria_analysis,
        tooltip: result.explanation,
        explanation: result.explanation,
        timestamp: result.timestamp,
        type: 'ai_filter',
        prompt: prompt,
        icon: 'magic',
        category_id: 'personal'
      };

      onSuccess(newList);
    })
    .catch(err => {
      console.error('AI Filter error:', err);
      setError(err.message || 'Failed to process AI filter request. Please try again.');
    })
    .finally(() => setIsLoading(false));
  };

  return (
    <div className="w-96">
      <form onSubmit={handleSubmit} className="divide-y divide-gray-100">
        <div className="p-4">
          
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Example: Find technology companies whose revenue growth is accelerating. Mid market"
            className="mt-2 w-full px-3 py-2 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows={3}
          />
          {error && (
            <div className="mt-2 text-sm text-red-600 bg-red-50 p-2 rounded-md border border-red-200">
              {error}
            </div>
          )}
        </div>

        <div className="p-3 bg-gray-50 rounded-b-lg flex justify-between items-center">
          <div className="text-xs text-gray-500">
            {isLoading ? 'Processing...' : 'AI will analyze your request'}
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-3 py-1 text-xs text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-3 py-1 text-xs bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Processing...' : 'Create List'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AIFilterModal; 