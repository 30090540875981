import React from 'react';
import PropTypes from 'prop-types';

const ProfileModal = ({ loggedInUser, onDeleteProfile }) => {
  // Handle both string and object cases
  const userEmail = typeof loggedInUser === 'string' ? loggedInUser : loggedInUser?.email;
  const userName = typeof loggedInUser === 'string' ? null : loggedInUser?.name;

  return (
    <div className="profile-modal-content">
      <h3 className="profile-modal-title">Account Information</h3>
      <p className="profile-modal-email">
        {userName ? `Logged in as: ${userName} (${userEmail})` : `Logged in as: ${userEmail}`}
      </p>
      <a 
        href="https://billing.stripe.com/p/login/00g9Er4TpfdqdHOcMM"
        target="_blank"
        rel="noopener noreferrer"
        className="profile-modal-button profile-modal-button-blue"
      >
        Manage Subscription
      </a>
      <button
        onClick={onDeleteProfile}
        className="profile-modal-button profile-modal-button-red"
      >
        Delete Profile and Watchlist Data
      </button>
    </div>
  );
};

ProfileModal.propTypes = {
  loggedInUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ]).isRequired,
  onDeleteProfile: PropTypes.func.isRequired
};

export default ProfileModal;