import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FiChevronUp, FiChevronDown, FiMenu, FiSliders } from 'react-icons/fi';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import Tooltip from './Tooltip';

function SortableHeader({ column, requestSort, sortConfig, onSettingsClick, onColorSettingsClick }) {
  // Determine if the column is draggable
  const isDraggable = !column.isFixed && !column.isActionsColumn;

  // Apply useSortable only if the column is draggable
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: column.key, disabled: !isDraggable });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? '#e2e8f0' : '',
  };

  return (
    <th ref={setNodeRef} style={style} className="px-4 py-2 border-b">
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1">
          {isDraggable && (
            <span
              {...listeners}
              {...attributes}
              className="cursor-move mr-2 text-gray-400 hover:text-gray-600 drag-handle"
              onClick={(e) => e.stopPropagation()}
            >
              <FiMenu style={{ width: '16px', height: '16px' }} />
            </span>
          )}
          <Tooltip text={column.tooltip}>
            <span
              className={`flex items-center ${column.isSortable ? 'cursor-pointer text-gray-700 hover:text-gray-900' : 'text-gray-400'}`}
              onClick={(e) => {
                e.stopPropagation();
                if (column.isSortable) {
                  requestSort(column.sortField || column.key);
                }
              }}
            >
              {column.label}
              {column.isSortable && sortConfig.key === (column.sortField || column.key) && (
                <span className="ml-1">
                  {sortConfig.direction === 'ascending' ? (
                    <FiChevronUp className="text-gray-500" />
                  ) : (
                    <FiChevronDown className="text-gray-500" />
                  )}
                </span>
              )}
            </span>
          </Tooltip>
        </div>
        
        {(column.key === 'company' || column.label === 'Company') && (
          <div className="flex items-center ml-auto">
            <Tooltip text="Customize color coding">
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onColorSettingsClick();
                }}
                className="cursor-pointer text-gray-600 hover:text-gray-800 transition-colors"
              >
                <PaletteOutlinedIcon style={{ 
                  width: '18px', 
                  height: '18px',
                  filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.1))'
                }} />
              </span>
            </Tooltip>
          </div>
        )}
        
        {column.isActionsColumn && (
          <div className="flex items-center">
            <Tooltip text="Configure columns">
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onSettingsClick();
                }}
                className="cursor-pointer text-gray-600 hover:text-gray-800 transition-colors"
              >
                <FiSliders 
                  size={18}
                  style={{
                    filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.1))'
                  }}
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </th>
  );
}

export default SortableHeader;
