import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
import { ALL_COLUMNS } from '../config/columnConfig';
import './SettingsDialog.css';

const SettingsDialog = ({ isOpen, onClose, onSave, initialSettings }) => {
  const [settings, setSettings] = useState(DEFAULT_COLOR_SETTINGS);

  useEffect(() => {
    if (initialSettings && Array.isArray(initialSettings) && initialSettings.length > 0) {
      setSettings(initialSettings);
    } else {
      setSettings(DEFAULT_COLOR_SETTINGS);
    }
  }, [initialSettings]);

  const handleAddRule = () => {
    setSettings(prevSettings => [
      ...prevSettings,
      {
        conditions: [{ field: 'trafficChange', operator: '>', value: 0 }],
        color: '#ffffff',
      }
    ]);
  };

  const handleRemoveRule = (ruleIndex) => {
    setSettings(prevSettings => prevSettings.filter((_, index) => index !== ruleIndex));
  };

  const handleAddCondition = (ruleIndex) => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[ruleIndex].conditions.push({ field: 'trafficChange', operator: '>', value: 0 });
      return newSettings;
    });
  };

  const handleRemoveCondition = (ruleIndex, conditionIndex) => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[ruleIndex].conditions = newSettings[ruleIndex].conditions.filter((_, index) => index !== conditionIndex);
      return newSettings;
    });
  };

  const handleConditionChange = (ruleIndex, conditionIndex, field, value) => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[ruleIndex].conditions[conditionIndex][field] = value;
      return newSettings;
    });
  };

  const handleColorChange = (ruleIndex, color) => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[ruleIndex].color = color;
      return newSettings;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitting settings:', settings);
    onSave(settings, 'colorSettings');  // Make sure 'colorSettings' is the correct key
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[60vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-gray-900 mb-4 pb-2 border-b border-gray-200"
                >
                  Row Color Coding Settings
                </Dialog.Title>
                <div className="mt-2">
                  {settings.map((rule, ruleIndex) => (
                    <div key={ruleIndex} className="rule-container">
                      <input
                        type="color"
                        value={rule.color}
                        onChange={(e) => handleColorChange(ruleIndex, e.target.value)}
                        className="color-picker"
                      />
                      <span>If all conditions are true:</span>
                      {rule.conditions.map((condition, conditionIndex) => (
                        <div key={conditionIndex} className="condition-row">
                          <select
                            value={condition.field}
                            onChange={(e) => handleConditionChange(ruleIndex, conditionIndex, 'field', e.target.value)}
                            className="condition-select"
                          >
                            {ALL_COLUMNS.map(column => (
                              <option key={column.key} value={column.key}>{column.label}</option>
                            ))}
                          </select>
                          <select
                            value={condition.operator}
                            onChange={(e) => handleConditionChange(ruleIndex, conditionIndex, 'operator', e.target.value)}
                            className="condition-select"
                          >
                            <option value=">">is greater than</option>
                            <option value="<">is less than</option>
                            <option value="=">is equal to</option>
                            <option value=">=">is greater than or equal to</option>
                            <option value="<=">is less than or equal to</option>
                          </select>
                          <input
                            type="number"
                            value={condition.value}
                            onChange={(e) => handleConditionChange(ruleIndex, conditionIndex, 'value', parseFloat(e.target.value))}
                            className="condition-input"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveCondition(ruleIndex, conditionIndex)}
                            className="remove-condition-btn"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => handleAddCondition(ruleIndex)}
                        className="add-condition-btn"
                      >
                        Add Condition
                      </button>
                      <button
                        type="button"
                        onClick={() => handleRemoveRule(ruleIndex)}
                        className="remove-rule-btn"
                      >
                        Remove Rule
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddRule}
                    className="add-rule-btn"
                  >
                    Add Rule
                  </button>
                </div>
                <div className="dialog-actions">
                  <button type="button" onClick={onClose} className="cancel-btn">
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      onSave(settings);
                      onClose();
                    }}
                    className="save-btn"
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SettingsDialog;
