import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { daysUntilEarnings } from "./utils"; // Import pure utility function

const EarningsBadge = ({ date, isEstimated }) => {
    const [tooltipStyle, setTooltipStyle] = useState({});
    const iconRef = useRef(null);
  
    const updateTooltipPosition = () => {
      if (iconRef.current) {
        const rect = iconRef.current.getBoundingClientRect();
        setTooltipStyle({
          left: `${rect.left}px`,
          top: `${rect.top - 30}px`, // Position above the icon
        });
      }
    };
  
    const days = daysUntilEarnings(date);
    let badgeClass = "";
    let badgeContent = "";
  
    if (days === null) {
      badgeClass = "bg-gray-200";
      badgeContent = "N/A";
    } else if (days < 0) {
      badgeClass = "bg-red-500 text-white";
      const daysAgo = Math.abs(days);
      badgeContent = `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    } else if (days === 0) {
      badgeClass = "bg-gray-800 text-white";
      badgeContent = "Today";
    } else {
      badgeClass = "bg-blue-200";
      badgeContent = `${days} day${days > 1 ? "s" : ""}`;
    }
  
    return (
      <span
        className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-semibold ${badgeClass}`}
      >
        {isEstimated && (
          <span
            className="earnings-estimate-tooltip-container"
            onMouseEnter={updateTooltipPosition}
          >
            <FontAwesomeIcon
              ref={iconRef}
              icon={faExclamationTriangle}
              className="earnings-estimate-icon"
            />
            <span className="earnings-estimate-tooltip" style={tooltipStyle}>
              Estimated earnings date
            </span>
          </span>
        )}
        {badgeContent}
      </span>
    );
  };

export default EarningsBadge;
