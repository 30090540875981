import React from 'react';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SignalIcon = ({ signal }) => {
  const isBuySignal = signal.type.toUpperCase() === 'BUY';
  const strength = parseFloat(signal.strength) || 0;
  
  const style = {
    color: isBuySignal ? '#16a34a' : '#dc2626', // green-600 : red-600
    opacity: 0.3 + (strength * 0.7),
    width: '20px',
    height: '20px',
    padding: '2px',
    borderRadius: '50%'
  };

  const signalIcons = {
    'Double Bottom Confirmation': WaterfallChartIcon,
    'Golden Cross with Volume': TrendingUpIcon,
    'Death Cross with Volume': TrendingDownIcon,
    'Gap Up Momentum': CallMadeIcon,
    'Gap Down Momentum': CallReceivedIcon,
    'Momentum Breakout': ShowChartIcon,
    'Strong Oversold Reversal': NorthIcon,
    'Strong Overbought Reversal': SouthIcon,
    'Resistance Rejection': ArrowDownwardIcon,
    'Support Bounce': ArrowUpwardIcon
  };

  const IconComponent = signalIcons[signal.name] || ShowChartIcon;
  
  return (
    <div className="relative group">
      <IconComponent sx={style} />
      <div className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded p-2 z-10 -mt-1 ml-6 whitespace-nowrap">
        {signal.name}<br/>
        Strength: {(signal.strength * 100).toFixed(0)}%<br/>
        Price: ${signal.price.toFixed(2)}
      </div>
    </div>
  );
};

const TechnicalSignalIcons = ({ signals }) => {
  if (!signals || signals.length === 0) return null;

  // Sort by strength and take top 3
  const topSignals = [...signals]
    .sort((a, b) => b.strength - a.strength)
    .slice(0, 3);

  // If there are more signals, add a count indicator
  const hasMoreSignals = signals.length > 3;

  return (
    <div className="flex gap-1 items-center">
      {topSignals.map((signal, index) => (
        <SignalIcon key={`${signal.created_at}-${index}`} signal={signal} />
      ))}
      {hasMoreSignals && (
        <div className="text-xs text-gray-500 ml-1" title={`${signals.length - 3} more signals`}>
          +{signals.length - 3}
        </div>
      )}
    </div>
  );
};

export default TechnicalSignalIcons; 