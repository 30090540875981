import React, { useState, useEffect } from 'react';

const Favicon = ({ website, companyName, alt, className }) => {
  const [iconSrc, setIconSrc] = useState('');
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    const loadImage = async (src) => {
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
      });
      return src;
    };

    const fetchFavicon = async () => {
      if (!website) {
        console.log(`No website provided for ${companyName}`);
        setLoadError(true);
        return;
      }

      const fullUrl = website.startsWith('http') ? website : `https://${website}`;
      const domain = new URL(fullUrl).hostname;

      // Prioritized list of favicon locations
      const iconLocations = [
        `${fullUrl}/favicon.ico`,
        `${fullUrl}/favicon.png`,
        `${fullUrl}/apple-touch-icon.png`,
        `${fullUrl}/apple-touch-icon-precomposed.png`,
        // Only use Google's service as a last resort
        `https://www.google.com/s2/favicons?domain=${domain}&sz=64`
      ];

      for (let location of iconLocations) {
        try {
          console.log(`Attempting to load favicon from: ${location}`);
          const validSrc = await loadImage(location);
          console.log(`Successfully loaded favicon from: ${validSrc}`);
          setIconSrc(validSrc);
          return;
        } catch (error) {
          console.error(`Failed to load favicon from ${location}:`, error.message);
        }
      }

      console.log(`Failed to find valid favicon for ${companyName} at any location`);
      setLoadError(true);
    };

    fetchFavicon();
  }, [website, companyName]);

  if (loadError || !iconSrc) {
    const fallbackLetter = companyName ? companyName[0].toUpperCase() : 
                           website ? new URL(website).hostname[0].toUpperCase() : '?';
    return (
      <div className={`${className} bg-gray-300 rounded-full flex items-center justify-center`}>
        <span className="text-gray-600 font-bold">{fallbackLetter}</span>
      </div>
    );
  }

  return (
    <img
      src={iconSrc}
      alt={alt}
      className={className}
      onError={(e) => {
        console.error(`Error displaying image from ${iconSrc}`);
        setLoadError(true);
      }}
    />
  );
};

export default Favicon;