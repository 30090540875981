import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useWatchlistData, fetchScreenData, useEconomicData, useMarketAnalysis, updateWatchlist, updateUserData, useCategories, createUserList,useUserLists } from '../services/api';
import StockTable from './StockTable';
import CONFIG from '../config/config';
import axios from 'axios';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import { jwtDecode } from 'jwt-decode';
import EarningsWidget from './EarningsWidget';
//import DailyMovementsWidget from './DailyMovementsWidget';
import AddSymbolButton from './AddSymbolButton';
import Modal from 'react-modal';
import ProfileModal from './ProfileModal';
import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
//import MarketAnalysisRow from './MarketAnalysis';
import MarketAnalysisRow from './MarketAnalysisRow2';
import { DEFAULT_COLUMNS, ALL_COLUMNS } from '../config/columnConfig'; // Added import
import { loadSettings, saveSettings } from '../utils/settings';
import FilterDropdowns from './FilterDropdown';
import SettingsDialog from './SettingsDialog';
import { MARKET_CAP_TIERS } from './MarketCapFilter';
import ReactDOM from 'react-dom';
import { publicFetch } from '../utils/authenticatedFetch';
import { isTokenExpired } from '../utils/auth';
import { checkAndRefreshToken } from '../utils/auth';

library.add(fas);

const DEBUG = {
  columns: false,    // Column-related logs
  data: true,       // Data loading logs
  filters: true,    // Filter-related logs
  sync: false,      // User data sync logs
  settings: false,  // Settings-related logs
  market: false     // Market analysis logs
};

// Define the semi-permanent status message
const APP_STATUS = {
  message: "🚀 This app is still in active development mode. 💎 Use code VfjF0RPi for 50% OFF FOREVER discount as a thank you for your early support! ⭐",
  type: "info"
};

const getIconName = (iconString) => {
  return iconString.startsWith('fa-') ? iconString.slice(3) : iconString;
};

const calculateTrialDaysLeft = (timestamp) => {
  const today = new Date();
  const firstUseDate = new Date(timestamp);
  const diffTime = Math.abs(today - firstUseDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.max(0, 21 - diffDays);
};

const filterCompaniesByMarketCap = (companies, selectedTiers) => {
  if (!Array.isArray(companies)) return [];
  if (!selectedTiers || selectedTiers.length === 0) return companies;
  
  return companies.filter(company => {
    // If no market cap tiers are selected, show all companies
    if (selectedTiers.length === 0) return true;

    const marketCap = company.marketCap;

    // Include companies with null/undefined market cap if 'unknown' tier is selected
    if (marketCap === null || marketCap === undefined) {
      return selectedTiers.includes('unknown');
    }

    // Check if company's market cap falls within any of the selected tiers
    return selectedTiers.some(tierId => {
      const tier = MARKET_CAP_TIERS.find(t => t.id === tierId);
      if (!tier) return false;
      return marketCap >= tier.min && marketCap < tier.max;
    });
  });
};

// 1. Add a more robust loading control system
const loadingControl = {
  current: false,
  lastLoad: 0,
  minInterval: 1000, // Minimum time between loads in ms
  isThrottled: function() {
    const now = Date.now();
    return this.current || (now - this.lastLoad < this.minInterval);
  },
  start: function() {
    if (this.isThrottled()) return false;
    this.current = true;
    this.lastLoad = Date.now();
    return true;
  },
  end: function() {
    this.current = false;
  }
};

// Define a helper to poll for data on new symbols
async function pollForNewSymbols(symbols, fetchFn, maxWaitMs = 20000, intervalMs = 2000) {
  return new Promise((resolve, reject) => {
    let elapsed = 0;

    const checkData = async () => {
      try {
        const data = await fetchFn(null, null, symbols);

        if (Array.isArray(data) && data.length > 0) {
          resolve(data); // We have actual data
        } else {
          elapsed += intervalMs;
          if (elapsed >= maxWaitMs) {
            reject(new Error("Timeout waiting for data on new AI list."));
          } else {
            setTimeout(checkData, intervalMs);
          }
        }
      } catch (error) {
        reject(error);
      }
    };

    checkData();
  });
}

const App = ({ env }) => {
  // 1. User authentication state
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('info');
  
  // 2. User settings and lists state
  const [userSettings, setUserSettings] = useState(() => {
    const savedSettings = loadSettings();
    return savedSettings || {
      colorSettings: DEFAULT_COLOR_SETTINGS,
      columns: DEFAULT_COLUMNS,
      marketCapFilters: MARKET_CAP_TIERS.map(tier => tier.id),
      lists: [] // Initialize empty lists array
    };
  });

  const stableUserLists = useMemo(() => {
    // Return the exact same array object if userSettings.lists hasn't changed meaningfully
    // This can be as simple as returning userSettings.lists directly, but to ensure
    // we don't get a new reference, we can do a shallow compare or check if it's identical.
    return userSettings.lists;
  }, [JSON.stringify(userSettings.lists)]);

  // 3. Modal state
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: null });
  const [isColorSettingsOpen, setIsColorSettingsOpen] = useState(false);
  const [colorSettings, setColorSettings] = useState(DEFAULT_COLOR_SETTINGS);

  // 4. Filter state
  const [activeFilters, setActiveFilters] = useState(() => ({
    marketCap: userSettings.marketCapFilters || MARKET_CAP_TIERS.map(tier => tier.id)
  }));

  // 3. Selected tab state (depends on loggedInUser)
  const [selectedTab, setSelectedTab] = useState(() => {
    if (loggedInUser) {
      return 'watchlist';
    }

    console.log('Initializing selectedTab...');
    const savedSymbols = localStorage.getItem('companiesSymbols');
    const watchlistSymbols = savedSymbols ? JSON.parse(savedSymbols) : [];
    console.log('Initial watchlist symbols:', watchlistSymbols);

    if (!watchlistSymbols.length) {
      return 'in_the_news';
    }
    return 'watchlist';
  });

  // 3. Rest of your existing state declarations
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesSymbols, setCompaniesSymbols] = useState(() => {
    const savedSymbols = localStorage.getItem('companiesSymbols');
    return savedSymbols ? JSON.parse(savedSymbols) : [];
  });
  const [news, setNews] = useState([]);
  const [analysis, setAnalysis] = useState('');
  const [guidance, setGuidance] = useState('');
  const [relatedEquities, setRelatedEquities] = useState([]);
  const [error, setError] = useState('');
  const [firstUseTimestamp, setFirstUseTimestamp] = useState(() => {
    const savedTimestamp = localStorage.getItem('firstUseTimestamp');
    return savedTimestamp || new Date().toISOString();
  });
  const [trialDaysLeft, setTrialDaysLeft] = useState(() => calculateTrialDaysLeft(firstUseTimestamp));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedTimeframes, setSelectedTimeframes] = useState([0]);
  const [geography, setGeography] = useState('US');
  const [showEconomicEvents, setShowEconomicEvents] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const isSyncing = useRef(false);
  const lastSyncedData = useRef({});

  const { data: marketAnalysisData, isLoading: isLoadingMarketAnalysis } = useMarketAnalysis();
  const { data: watchlistData, refetch: refetchWatchlistData } = useWatchlistData(
    loggedInUser?.email || ''
  );
  const { 
    data: userListsData, 
    refetch: refetchUserLists, 
    deleteList,
    createList,
    isLoading: isListsLoading,
    error: listsError 
  } = useUserLists(loggedInUser?.email);

  // Add logging to track userListsData changes
  useEffect(() => {
    console.log('userListsData updated:', userListsData);
  }, [userListsData]);

  const updateUserWatchlist = useCallback(async (symbols) => {
    if (!loggedInUser) return;
    try {
      await updateWatchlist(loggedInUser, symbols);
      await refetchWatchlistData();
    } catch (error) {
      console.error('Error updating user watchlist:', error);
      setError('Failed to update watchlist. Please try again.');
    }
  }, [loggedInUser, refetchWatchlistData]);

  const syncUserData = useCallback(async (user) => {
    if (!user || isSyncing.current) return;
    isSyncing.current = true;
    console.log('Starting user data sync for:', user);

    try {
      const result = await refetchWatchlistData();
      console.log('Fetched user data:', result);

      // Handle symbols merge
      const serverSymbols = result?.data?.companiesSymbols || [];
      const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
      const mergedSymbols = Array.from(new Set([...serverSymbols, ...localSymbols]));

      // Handle settings merge (excluding lists)
      const serverSettings = result?.data?.preferences || {};
      const localSettings = loadSettings();
      
      // Don't merge lists - only use server lists for the logged-in user
      const { lists: _, ...otherServerSettings } = serverSettings;
      const { lists: __, ...otherLocalSettings } = localSettings;
      
      const mergedSettings = {
        ...otherLocalSettings,
        ...otherServerSettings
      };

      // Update local state
      setCompaniesSymbols(mergedSymbols);
      setUserSettings(mergedSettings); // Lists will be handled by useUserLists hook

      // Update local storage
      localStorage.setItem('companiesSymbols', JSON.stringify(mergedSymbols));
      localStorage.setItem('userSettings', JSON.stringify(mergedSettings));

      // Use UUID when syncing back to server
      await updateUserData({
        id: user.id,    // Prefer UUID
        email: user.email // Fallback
      }, {
        preferences: mergedSettings,
        companiesSymbols: mergedSymbols
      });

    } catch (error) {
      console.error('Error in syncUserData:', error);
    } finally {
      isSyncing.current = false;
    }
  }, [refetchWatchlistData, updateUserData]);

  // Add this helper function to merge user lists
  const mergeUserLists = (localLists, serverLists) => {
    const mergedLists = [...serverLists];
    
    localLists.forEach(localList => {
      const existingList = mergedLists.find(serverList => serverList.id === localList.id);
      
      if (!existingList) {
        // If local list doesn't exist on server, add it
        mergedLists.push(localList);
      } else {
        // If list exists, merge symbols
        const mergedSymbols = Array.from(new Set([
          ...(existingList.symbols || []),
          ...(localList.symbols || [])
        ]));
        
        // Update existing list with merged data
        Object.assign(existingList, {
          ...localList,
          symbols: mergedSymbols,
          updated_at: new Date().toISOString()
        });
      }
    });
    
    return mergedLists;
  };

  // Modify handleSettingsChange to handle lists separately
  const handleSettingsChange = useCallback((key, value) => {
    setUserSettings(prev => {
      const updated = {
        ...prev,
        [key]: value
      };
      localStorage.setItem('userSettings', JSON.stringify(updated));

      // If this is a lists update, don't sync it through user preferences
      if (key === 'lists' && loggedInUser?.email) {
        // Lists are handled by the useUserLists hook and its mutations
        return updated;
      }

      // For other settings, sync to server
      if (loggedInUser?.email) {
        const { lists, ...settingsWithoutLists } = updated;
        updateUserData(loggedInUser.email, {
          preferences: settingsWithoutLists
        }).catch(error => {
          console.error('Error syncing settings to server:', error);
        });
      }

      return updated;
    });
  }, [loggedInUser]);

  // Your existing handleSettingsSave function
  const handleSettingsSave = useCallback((value, category) => {
    const updatedSettings = saveSettings(category, value);
    setUserSettings(updatedSettings);
    
    // If user is logged in, sync to server
    if (loggedInUser) {
      updateUserData(loggedInUser, { 
        preferences: updatedSettings 
      }).catch(error => {
        console.error('Error saving settings:', error);
      });
    }
    
    return updatedSettings;
  }, [loggedInUser]);

  useEffect(() => {
    if (marketAnalysisData) {
      if (DEBUG.market) {
        console.log('Market Analysis Data:', marketAnalysisData);
      }
      setAnalysis(marketAnalysisData.analysis);
      setGuidance(marketAnalysisData.guidance);
      setRelatedEquities(marketAnalysisData.relatedEquities);
    }
  }, [marketAnalysisData]);

  const queryClient = useQueryClient();

  queryClient.setQueryDefaults(['marketAnalysis'], {
    staleTime: 60 * 60 * 1000, // 1 hour
    cacheTime: 2 * 60 * 60 * 1000, // 2 hours
  });

  

  const { 
    data: allEconomicEvents, 
    isLoading: isLoadingEconomicEvents, 
    error: economicError 
  } = useEconomicData(geography);

  // 1. Add a ref to track loading state
  const isLoadingRef = useRef(false);

  // 2. Modify loadData to use the new loading control
  const loadData = useCallback(async (forceRefresh = false) => {
    if (!forceRefresh && loadingControl.isThrottled()) {
      console.log('Load skipped - throttled');
      return;
    }

    if (!loadingControl.start()) {
      return;
    }

    console.log('Loading data for tab:', selectedTab);
    
    try {
      setIsLoading(true);
      let rawData;
      
      // Handle AI-generated or custom lists
      if (selectedTab?.startsWith('ai_')) {
        // Find the list in userListsData
        const selectedList = userListsData?.find(list => list.id === selectedTab);
        if (selectedList?.symbols) {
          rawData = await fetchScreenData(null, null, selectedList.symbols);
        } else {
          console.log('No symbols found for list:', selectedTab);
          rawData = [];
        }
      } else if (selectedTab === 'watchlist') {
        if (DEBUG.data) console.log('Loading watchlist data for:', loggedInUser || 'local storage');
        
        if (loggedInUser) {
          const result = await refetchWatchlistData();
          const watchlistResult = result?.data;
          rawData = Array.isArray(watchlistResult?.companies)
            ? watchlistResult.companies
            : [];
        } else {
          const localSymbols = JSON.parse(localStorage.getItem('companiesSymbols') || '[]');
          if (localSymbols.length > 0) {
            rawData = await fetchScreenData('watchlist', null, localSymbols);
          } else {
            rawData = [];
          }
        }
      } else {
        rawData = await fetchScreenData(selectedTab);
      }

      if (DEBUG.data) {
        console.log('Raw data loaded:', {
          tab: selectedTab,
          count: rawData?.length,
          firstItem: rawData?.[0]
        });
      }

      const filteredData = filterCompaniesByMarketCap(rawData, activeFilters.marketCap);
      
      if (DEBUG.filters) {
        console.log('After market cap filter:', {
          original: rawData?.length,
          filtered: filteredData?.length,
          activeFilters: activeFilters.marketCap
        });
      }

      setCompaniesData(filteredData);
    } catch (error) {
      console.error('Error loading data:', error);
      setError(`Failed to load data: ${error.message}`);
    } finally {
      loadingControl.end();
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, [selectedTab, activeFilters.marketCap, userListsData]);

  // 3. Modify the filter effect to use the ref
  useEffect(() => {
    if (!activeFilters) return;
    
    const timeoutId = setTimeout(() => {
      if (!loadingControl.isThrottled()) {
        console.log('Filter effect triggered:', activeFilters);
        loadData(true);
      }
    }, 500); // Longer debounce for filters
    
    return () => clearTimeout(timeoutId);
  }, [activeFilters, loadData]);

  // 4. Add initial load effect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!loadingControl.isThrottled()) {
        loadData();
      }
    }, 100); // Small delay to allow state to settle
    
    return () => clearTimeout(timeoutId);
  }, [selectedTab]);

  const handleFilterApply = useCallback((filters) => {
    console.log('Filter apply start with:', filters);

    // Update state first
    setActiveFilters(filters);
    
    // Save settings
    const updatedSettings = saveSettings('marketCapFilters', filters.marketCap);
    
    if (loggedInUser) {
      updateUserData(loggedInUser, {
        preferences: {
          ...userSettings,
          marketCapFilters: filters.marketCap
        }
      }).catch((error) => {
        console.error('Error saving filters:', error);
      });
    }
  }, [loggedInUser, userSettings]);

  // Data loading effect
  useEffect(() => {
    console.log('loadData effect triggered', {
      selectedTab,
      activeFilters: activeFilters.marketCap,
      stableUserLists: stableUserLists?.map(list => list.id)
    });
    loadData();
  }, [selectedTab, activeFilters, stableUserLists, loadData]);

  const debouncedSync = useCallback(
    debounce((email, data) => {
      if (JSON.stringify(data) !== JSON.stringify(lastSyncedData.current)) {
        syncUserData(email, data);
        lastSyncedData.current = data;
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (loggedInUser && companiesSymbols.length > 0) {
      debouncedSync(loggedInUser, companiesSymbols);
    }
  }, [loggedInUser, companiesSymbols, debouncedSync]);

  const handleAddToWatchList = useCallback(async (companyOrSymbol) => {
    const tickerSymbol = typeof companyOrSymbol === 'string' 
      ? companyOrSymbol.trim().toUpperCase()
      : companyOrSymbol.tickerSymbol;

    if (!tickerSymbol) {
      setError('Invalid symbol. Please enter a valid stock symbol.');
      setStatusType('error');
      setTimeout(() => setError(''), 5000);
      return;
    }

    if (companiesSymbols.includes(tickerSymbol)) {
      setError(`${tickerSymbol} is already in your watch list.`);
      setStatusType('error');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setIsLoading(true);
    try {
      const updatedSymbols = [...companiesSymbols, tickerSymbol];
      
      // Update local state first
      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));

      // If logged in, sync to server
      if (loggedInUser?.email) {
        try {
          await updateWatchlist(loggedInUser, updatedSymbols);
        } catch (error) {
          console.error('Server sync failed:', error);
          // Keep local changes even if server sync fails
        }
      }

      // Fetch the data for the new symbol
      const newSymbolData = await fetchScreenData(null, null, [tickerSymbol]);
      if (newSymbolData && newSymbolData.length > 0) {
        setCompaniesData(prevData => [...prevData, newSymbolData[0]]);
      }

      setStatusMessage(`${tickerSymbol} added to watch list successfully.`);
      setStatusType('success');
      
    } catch (error) {
      console.error('Error adding new symbol:', error);
      setError(`Failed to add ${tickerSymbol} to watch list: ${error.message}`);
      setStatusType('error');
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setError('');
        setStatusMessage('');
      }, 5000);
    }
  }, [companiesSymbols, loggedInUser, updateWatchlist, fetchScreenData]);

  const handleDeleteFromWatchList = useCallback(async (tickerSymbol) => {
    try {
      const updatedSymbols = companiesSymbols.filter(symbol => symbol !== tickerSymbol);
      setCompaniesSymbols(updatedSymbols);
      localStorage.setItem('companiesSymbols', JSON.stringify(updatedSymbols));
  
      setCompaniesData(prevData => prevData.filter(company => company.tickerSymbol !== tickerSymbol));
  
      if (loggedInUser) {
        await updateUserWatchlist(updatedSymbols);
      }
    } catch (error) {
      if (DEBUG.data) {
        console.error(`Error deleting ${tickerSymbol} from watchlist:`, error);
      }
      setError(`Failed to delete ${tickerSymbol} from watchlist.`);
      setTimeout(() => setError(''), 5000);
    }
  }, [companiesSymbols, loggedInUser, updateUserWatchlist]);

  const handleLoginSuccess = useCallback((userData) => {
    const user = {
      id: userData.id, // Store UUID
      email: userData.email,
      name: userData.name || userData.email.split('@')[0],
    };
    
    setLoggedInUser(user);
    localStorage.setItem('userId', userData.id); // Store UUID in localStorage
    
    // Use UUID for sync
    syncUserData(user);
    
    setSelectedTab('watchlist');
    setStatusMessage('Successfully logged in!');
    setStatusType('success');
    setTimeout(() => setStatusMessage(''), 5000);
  }, [syncUserData]);

  const handleLogout = useCallback(() => {
    setLoggedInUser(null);
    setStatusMessage('You have been logged out.');
    setStatusType('info');
    setTimeout(() => setStatusMessage(''), 5000);
  }, []);

  useEffect(() => {
    const initializeUserData = async () => {
      if (loggedInUser && !isSyncing.current) {
        setIsLoading(true);
        isSyncing.current = true;
        try {
          await syncUserData(loggedInUser);
          await queryClient.invalidateQueries(['userData', loggedInUser.id]);
          await queryClient.invalidateQueries(['economicData']);
          await queryClient.invalidateQueries(['companies']);
          await loadData();
        } catch (error) {
          if (DEBUG.sync) {
            console.error('Error initializing user data:', error);
          }
          setError('Failed to initialize user data. Please try again.');
        } finally {
          setIsLoading(false);
          isSyncing.current = false;
        }
      }
    };
  
    initializeUserData();
  }, [loggedInUser]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const googleToken = localStorage.getItem('googleToken');

        if (token) {
          console.log('Validating auth token...');
          const validateResponse = await fetch('https://api.1stprinciples.pro/auth/validateToken', {
            method: 'POST',
            body: token
          });

          if (validateResponse.ok) {
            const user = await validateResponse.json();
            console.log('Auth token valid, user:', user);
            setLoggedInUser(user);
            return;
          }
          console.log('Auth token invalid, removing...');
          localStorage.removeItem('authToken');
        }

        // Fall back to Google auth
        if (googleToken && !isTokenExpired(googleToken)) {
          console.log('Using Google token...');
          const decodedToken = jwtDecode(googleToken);
          if (decodedToken?.email) {
            setLoggedInUser({
              email: decodedToken.email,
              name: decodedToken.name || decodedToken.email.split('@')[0]
            });
          }
        } else if (googleToken) {
          console.log('Google token expired, removing...');
          localStorage.removeItem('googleToken');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
      }
    };

    checkAuth();
  }, []);

  const filteredEconomicEvents = useMemo(() => {
    if (!allEconomicEvents?.events || !showEconomicEvents) return [];
    
    // Get user's timezone offset in minutes
    const userTimezoneOffset = new Date().getTimezoneOffset();
    
    // Get current date in user's timezone
    const today = new Date();
    
    // Get the start of the current week (Sunday) in user's timezone
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    startOfWeek.setHours(0, 0, 0, 0);
    
    // Get the selected timeframes from userSettings
    const selectedTimeframes = userSettings?.economicEvents?.selectedTimeframes || [0];

    return Object.entries(allEconomicEvents.events).flatMap(([date, events]) => {
      const eventDate = new Date(date);
      if (isNaN(eventDate)) {
        // Log and skip this entry
        console.warn('Skipping invalid date:', date);
        return [];
      }

      // If date is valid, proceed
      const localEventDate = new Date(eventDate);
      localEventDate.setHours(0, 0, 0, 0);

      const timeDiff = localEventDate.getTime() - startOfWeek.getTime();
      const weekDiff = Math.floor(timeDiff / (7 * 24 * 60 * 60 * 1000));

      if (selectedTimeframes.includes(weekDiff)) {
        return events.map(event => ({
          ...event,
          date,
          timeframe: weekDiff,
          weekCalculation: {
            eventDate: localEventDate.toISOString(),
            startOfWeek: startOfWeek.toISOString(),
            weekDiff,
          },
        }));
      }
      return [];
    });
  }, [allEconomicEvents?.events, showEconomicEvents, userSettings?.economicEvents?.selectedTimeframes]);

  // Initialize columns state
  const [columns, setColumns] = useState(() => {
    const savedSettings = loadSettings();
    const savedColumnKeys = savedSettings.columns || [];
    
    // Get fixed columns first
    const fixedColumns = ALL_COLUMNS.filter(col => col.isFixed);
    const fixedKeys = fixedColumns.map(col => col.key);
    
    // Combine fixed and saved columns, maintaining order
    const allColumnKeys = [...fixedKeys, ...savedColumnKeys.filter(key => !fixedKeys.includes(key))];
    
    return allColumnKeys
      .map(key => ALL_COLUMNS.find(col => col.key === key))
      .filter(Boolean);
  });

  // Handle saving column configuration
  const handleSaveColumnConfig = useCallback(
    (newColumnKeys) => {
      // Reconstruct the columns array directly from newColumnKeys
      const newColumns = newColumnKeys
        .map((key) => ALL_COLUMNS.find((col) => col.key === key))
        .filter(Boolean);

      setColumns(newColumns);

      // Save all selected column keys to local storage
      localStorage.setItem(
        'columnsConfig',
        JSON.stringify(newColumnKeys)
      );

      handleSettingsSave(newColumnKeys, 'columns');
    },
    [handleSettingsSave]
  );

  const handleColumnReorder = useCallback((newColumns) => {
    setColumns(newColumns);

    const newColumnKeys = newColumns.map((col) => col.key);

    // Save new order to localStorage
    localStorage.setItem('columnsConfig', JSON.stringify(newColumnKeys));

    // Update user settings
    handleSettingsSave(newColumnKeys, 'columns');
  }, [handleSettingsSave]);

  // Define handlers before using them in useMemo
  const handleColorSettingsClick = useCallback(() => {
    setIsColorSettingsOpen(true);
  }, []);

  const handleColorSettingsSave = useCallback((newSettings) => {
    setColorSettings(newSettings);
    const updatedSettings = saveSettings('colorSettings', newSettings);
    setUserSettings(updatedSettings);
    
    if (loggedInUser) {
      updateUserData(loggedInUser, { 
        preferences: updatedSettings 
      }).catch((error) => {
        if (DEBUG.settings) {
          console.error('Error saving color settings:', error);
        }
      });
    }
  }, [loggedInUser]);

  // 1) Obtain categories before using them
  const { data: categories } = useCategories();
  console.log('Raw categories from API:', categories);

  // 2) Now define the listExplanation after "categories" is declared
  const listExplanation = useMemo(() => {
    // 1) If we have an "activeList" from userListsData
    const activeList = userListsData?.find((l) => l.id === selectedTab);
    if (activeList) {
      // If it's an AI filter with .explanation or .tooltip
      if (activeList.type === 'ai_filter') {
        return activeList.short_description 
          || activeList.explanation 
          || activeList.tooltip 
          || '';
      }
      // Otherwise, for any other user list, prefer short_description
      if (activeList.short_description) {
        return activeList.short_description;
      }
      return '';
    }

    // 2) Fallback: if no activeList, look in categories
    if (categories && selectedTab) {
      // Try direct category match
      const parentCategory = categories.find((cat) => cat.id === selectedTab);
      if (parentCategory?.short_description) {
        return parentCategory.short_description;
      }
      if (parentCategory?.description) {
        return parentCategory.description;
      }

      // Else look for a filter in all categories
      for (const cat of categories) {
        const matchedFilter = cat.filters?.find((f) => f.id === selectedTab);
        if (matchedFilter?.short_description) {
          return matchedFilter.short_description;
        }
        if (matchedFilter?.description) {
          return matchedFilter.description;
        }
      }
    }
    // If none matched, return empty
    return '';
  }, [selectedTab, userListsData, categories]);

  // Now define memoizedStockTable
  const memoizedStockTable = useMemo(
    () => (
      <StockTable
        companyData={companiesData}
        economicEvents={filteredEconomicEvents}
        econAnalysis={allEconomicEvents?.analysis}
        onAddToWatchList={handleAddToWatchList}
        onDelete={handleDeleteFromWatchList}
        guidance={guidance}
        relatedEquities={relatedEquities}
        loadingTable={isLoading}
        selectedTimeframes={selectedTimeframes}
        setSelectedTimeframes={setSelectedTimeframes}
        geography={geography}
        setGeography={setGeography}
        showEconomicEvents={showEconomicEvents}
        setShowEconomicEvents={setShowEconomicEvents}
        selectedTab={selectedTab}
        colorSettings={colorSettings}
        columns={columns}
        onColumnReorder={handleColumnReorder}
        onColorSettingsClick={handleColorSettingsClick}
        userSettings={userSettings}
        onSettingsChange={handleSettingsChange}
        onColorSettingsSave={handleColorSettingsSave}
        listExplanation={listExplanation}
        news={news}
      />
    ),
    [companiesData, filteredEconomicEvents, handleAddToWatchList, handleDeleteFromWatchList, 
     guidance, relatedEquities, isLoading, selectedTimeframes, 
     setSelectedTimeframes, geography, setGeography, showEconomicEvents, 
     setShowEconomicEvents, selectedTab, colorSettings, columns, handleColumnReorder, 
     handleColorSettingsClick, userSettings, handleSettingsChange, handleColorSettingsSave, 
     userListsData, selectedTab, listExplanation, news]
  );

  if (DEBUG.settings) {
    console.log('APP_STATUS in App.js:', APP_STATUS);
  }

  const handleDeleteProfile = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        // Implement the delete profile logic here
        // For example:
        // await deleteUserProfile(loggedInUser);
        setLoggedInUser(null);
        localStorage.removeItem('authToken');
        setModalIsOpen(false);
        setStatusMessage('Your profile has been deleted successfully.');
        setStatusType('success');
      } catch (error) {
        console.error('Error deleting profile:', error);
        setStatusMessage('Failed to delete profile. Please try again.');
        setStatusType('error');
      }
    }
  }, [loggedInUser, setStatusMessage, setStatusType]);

  const handleProfileClick = useCallback(() => {
    setModalContent({
      title: 'Profile',
      content: <ProfileModal loggedInUser={loggedInUser} onDeleteProfile={handleDeleteProfile} onClose={() => setModalIsOpen(false)} />
    });
    setModalIsOpen(true);
  }, [loggedInUser, handleDeleteProfile]);

  const handleSubscriptionClick = useCallback(() => {
    setModalContent({
      title: 'Subscription',
      content: <p>Subscription management options will go here.</p>
    });
    setModalIsOpen(true);
  }, []);

  useEffect(() => {
    console.log('Modal open state changed:', modalIsOpen);
  }, [modalIsOpen]);

  const handleAddSymbolClick = useCallback(() => {
    setModalContent({
      title: 'Add Symbol to Watch List',
      content: (
        <AddSymbolButton 
          onAddSymbol={handleAddToWatchList}
          existingSymbols={companiesSymbols}
          isLoggedIn={!!loggedInUser}
          className="w-full"
        >
          Search and add symbols to your watch list
        </AddSymbolButton>
      )
    });
    setModalIsOpen(true);
  }, [handleAddToWatchList, companiesSymbols, loggedInUser]);


  useEffect(() => {
    if (userSettings.colorSettings) {
      setColorSettings(userSettings.colorSettings);
    }
    
    if (userSettings.columns) {
      const savedColumnKeys = userSettings.columns;
      const fixedColumns = ALL_COLUMNS.filter(col => col.isFixed);
      const fixedKeys = fixedColumns.map(col => col.key);
      
      // Combine fixed and saved columns, maintaining order
      const allColumnKeys = [...fixedKeys, ...savedColumnKeys.filter(key => !fixedKeys.includes(key))];
      
      const allColumns = allColumnKeys
        .map(key => ALL_COLUMNS.find(col => col.key === key))
        .filter(Boolean);
      
      if (allColumns.length > 0) {
        setColumns(allColumns);
      }
    }
  }, [userSettings]);

  if (DEBUG.columns) {
    console.log('columns in App.js before rendering StockTable:', columns);
  }
  // Load user preferences
  useEffect(() => {
    const loadUserPreferences = async () => {
      try {
        const savedSettings = await loadSettings();
        setUserSettings(savedSettings);

        if (savedSettings.columns) {
          const savedColumnKeys = savedSettings.columns;
          const fixedColumns = ALL_COLUMNS.filter((col) => col.isFixed).map(
            (col) => col.key
          );

          const allColumnKeys = Array.from(
            new Set([...fixedColumns, ...savedColumnKeys])
          );

          const allColumns = allColumnKeys
            .map((key) => ALL_COLUMNS.find((col) => col.key === key))
            .filter(Boolean);

          if (allColumns.length > 0) {
            setColumns(allColumns);
          }
        }
        // ... handle other settings ...
      } catch (error) {
        console.error("Error loading user preferences:", error);
      }
    };

    loadUserPreferences();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.inline-flex')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  const handleDropdownToggle = (categoryId) => {
    setActiveDropdown(current => current === categoryId ? null : categoryId);
  };

  const handleCategorySelect = (categoryId) => {
    if (categoryId === 'personal') {
      setSelectedTab('watchlist'); // Default to watchlist when My Lists is selected
    } else {
      setSelectedTab(categoryId);
    }
  };

  // Replace the filter persistence effect with an effect that handles both initial load and updates
  useEffect(() => {
    const savedSettings = loadSettings();
    if (savedSettings.marketCapFilters && 
        JSON.stringify(savedSettings.marketCapFilters) !== JSON.stringify(activeFilters.marketCap)) {
      console.log('Updating filters from saved settings:', savedSettings.marketCapFilters);
      setActiveFilters(prev => ({
        ...prev,
        marketCap: savedSettings.marketCapFilters
      }));
    }
  }, [activeFilters.marketCap]); // Only run when marketCap filters change

  // Add effect to log settings changes
  useEffect(() => {
    console.log('Current userSettings:', userSettings);
  }, [userSettings]);

  // Effect to handle empty watchlist
  useEffect(() => {
    const checkWatchlist = () => {
      console.log('Checking watchlist...');
      const savedSymbols = localStorage.getItem('companiesSymbols');
      const watchlistSymbols = savedSymbols ? JSON.parse(savedSymbols) : [];
      
      if (!watchlistSymbols.length && selectedTab === 'watchlist') {
        // Switch to in_the_news when watchlist is empty
        setSelectedTab('in_the_news'); // Changed from bellwether_stocks
      }
    };

    checkWatchlist();
  }, []); // Run once on mount

  // The new handleAIFilterSuccess that uses polling
  const handleAIFilterSuccess = useCallback(
    async (newList) => {
      try {
        if (!loggedInUser?.email) {
          setError("Please log in to create and save AI-generated lists");
          setStatusType("error");
          return;
        }

        // Put the dialog in a loading state while we do everything
        setStatusMessage("Creating AI Filter list...");
        setStatusType("info");
        setIsLoading(true);

        // 1) Insert the new AI list in your DB
        createList.mutate(newList, {
          onSuccess: async (serverResult) => {
            if (!serverResult?.id) {
              console.warn("Server didn't return a valid list ID:", serverResult);
              setError("Error creating list: invalid server response");
              setStatusType("error");
              setIsLoading(false);
              return;
            }

            // 2) Now the server "knows" this list & symbols. Poll until we get actual data
            setStatusMessage("Waiting for new AI list data to populate...");
            try {
              const polledData = await pollForNewSymbols(
                newList.symbols,
                fetchScreenData,
                20000, // up to 20s total
                2000   // poll every 2s
              );

              // Found actual data for these symbols
              setCompaniesData(polledData);
              setSelectedTab(serverResult.id);
              setStatusMessage("AI Filter list created successfully");
              setStatusType("success");
              // setModalIsOpen(false);
            } catch (timeoutError) {
              console.warn("Timeout fetching data for AI list:", timeoutError);
              // Show placeholders so the user sees the symbols 
              const fallbackRows = newList.symbols.map(sym => ({
                tickerSymbol: sym,
                name: "Data not ready yet",
                stockPrice: null,
              }));
              setCompaniesData(fallbackRows);
              setSelectedTab(serverResult.id);
              setStatusMessage("List created. Data not ready. Please try again later.");
              setStatusType("warning");
              // setModalIsOpen(false);
            } finally {
              setIsLoading(false);
            }
          },
          onError: (err) => {
            console.error("Failed to create AI list on server:", err);
            setError("Error creating list on server");
            setStatusType("error");
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("handleAIFilterSuccess error:", error);
        setError("Error creating AI filter list");
        setStatusType("error");
        setIsLoading(false);
      }
    },
    [
      loggedInUser,
      createList,
      setCompaniesData,
      setSelectedTab,
      setError,
      setStatusType,
      // setModalIsOpen
    ]
  );

  // Add delete handler
  const handleDeleteList = async (listId) => {
    try {
      if (!window.confirm('Are you sure you want to delete this list?')) {
        return;
      }

      setStatusMessage('Deleting list...');
      setStatusType('info');

      // If logged in, try to delete from server
      if (loggedInUser?.email) {
        try {
          await deleteList.mutateAsync(listId);
        } catch (error) {
          console.error('Failed to delete list from server:', error);
          // There's no local fallback here, 
          // because we trust the server as the source of truth.
        }
      }

      if (selectedTab === listId) {
        setSelectedTab('watchlist');
      }

      setStatusMessage('List deleted successfully');
      setStatusType('success');

    } catch (error) {
      console.error('Error deleting list:', error);
      setError('Failed to delete list');
      setStatusType('error');
    }
  };

  // Single sync effect for lists
  useEffect(() => {
    const syncLists = async () => {
        if (loggedInUser?.email) {
            try {
                console.log('Syncing lists for user:', loggedInUser.email);

                // Fetch the server lists from useUserLists
                const response = await refetchUserLists();
                const serverLists = response?.data || [];
                console.log('Server returned these lists:', serverLists);

                // NOTE: remove references to userSettings.lists,
                // since we want the separate table to handle them.

                // Currently, we do nothing else here — no merging or overwriting.
                // We simply trust useUserLists() to hold the authoritative data.

                // If you still have local-only lists, handle them in a different state or approach.
                // For now, we skip that since you mentioned the lists are in a separate table.

                console.log('Finished syncing lists. We are not overwriting userSettings.lists anymore.');
            } catch (error) {
                console.error('Error in list sync:', error);
                setError('Failed to sync lists');
            }
        }
    };

    syncLists();
  }, [loggedInUser, refetchUserLists, createList?.mutateAsync]);

  useEffect(() => {
    // Only do this if we have a non-empty statusMessage
    if (statusMessage) {
      const timer = setTimeout(() => {
        // Clear out the message, error, and revert to 'info' after 5 seconds
        setStatusMessage('');
        setError('');
        setStatusType('info');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [statusMessage, setStatusMessage, setError, setStatusType]);

  // Add back the news fetching effect
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await publicFetch(`${CONFIG.API_URLS.NEWS}`);
        const data = await response.json();
        if (data.articles) {
          setNews(data.articles);
          console.log('Fetched news articles:', data.articles.length);
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []); // Run once on mount

  return (
    <QueryClientProvider client={queryClient}>
    <div className="container mx-auto mt-4 px-4">
      <Header 
        onLoginSuccess={handleLoginSuccess}
        onLogout={handleLogout}
        loggedInUser={loggedInUser}
        trialDaysLeft={trialDaysLeft} 
        isSubscribed={isSubscribed} 
        statusMessage={statusMessage}
        statusType={statusType}
        appStatus={APP_STATUS}  // Always pass the APP_STATUS
        onProfileClick={handleProfileClick}
        onSubscriptionClick={handleSubscriptionClick}
        onSettingsSave={handleSettingsSave}
      />
     {/* <EarningsWidget apiUrl={CONFIG.API_URLS.EARNINGS_ANALYTICS} /> 
      <DailyMovementsWidget apiUrl={CONFIG.API_URLS.DAILY_MOVEMENT_ANALYTICS} />*/}
      <MarketAnalysisRow
        analysis={analysis}
        guidance={guidance}
        relatedEquities={relatedEquities}
        onAddSymbol={handleAddToWatchList}
        userSettings={userSettings}
        onSettingsChange={handleSettingsChange}
        news={news}
      />
      {error && <div className="text-red-500 text-center p-4">{error}</div>}
      <FilterDropdowns 
        selectedTab={selectedTab}
        onSelectTab={handleCategorySelect}
        onAddSymbol={handleAddToWatchList}
        loggedInUser={loggedInUser}
        existingSymbols={companiesSymbols}
        activeFilters={activeFilters}
        onFilterApply={handleFilterApply}
        watchlist={companiesData}
        categories={categories}
        userListsData={userListsData}
        onModalOpen={setModalIsOpen}
        onModalContentSet={setModalContent}
        onSuccess={handleAIFilterSuccess}
        onDeleteList={handleDeleteList}
        setError={setError}
      />
      {memoizedStockTable}
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={modalContent.title}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-auto">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold text-gray-900">{modalContent.title}</h2>
            <button
              onClick={() => setModalIsOpen(false)}
              className="text-gray-400 hover:text-gray-600 text-xl font-bold"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          <div className="p-4">
            {modalContent.content}
          </div>
        </div>
      </Modal>
      <SettingsDialog
        isOpen={isColorSettingsOpen}
        onClose={() => setIsColorSettingsOpen(false)}
        onSave={handleColorSettingsSave}
        initialSettings={colorSettings}
      />
      {isListsLoading && <div>Loading lists...</div>}
      {listsError && <div>Error loading lists: {listsError.message}</div>}
    </div>
    </QueryClientProvider>
  );
};

export default React.memo(App);
