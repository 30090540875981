// utils/authenticatedFetch.js
import CONFIG from '../config/config';
import { jwtDecode } from 'jwt-decode';
import { checkAndRefreshToken, isTokenExpired } from './auth';

export const authenticatedFetch = async (url, options = {}) => {
  try {
    let token = localStorage.getItem('authToken') || localStorage.getItem('googleToken');
    
    // If no token but we have userId in URL, use that
    if (!token && url.includes('userId=')) {
      return publicFetch(url, options);
    }

    // Check if token needs refresh
    if (token && isTokenExpired(token)) {
      console.log('Token expired, attempting refresh...');
      const refreshed = await checkAndRefreshToken();
      if (!refreshed) {
        console.log('Token refresh failed, falling back to userId if available');
        localStorage.removeItem('authToken');
        localStorage.removeItem('googleToken');
        
        // If URL has userId, use that instead of failing
        if (url.includes('userId=')) {
          return publicFetch(url, options);
        }
      } else {
        token = localStorage.getItem('authToken') || localStorage.getItem('googleToken');
      }
    }

    // Add auth header if we have a token
    const headers = {
      ...options.headers,
      'Content-Type': 'application/json'
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      ...options,
      headers
    });

    // If unauthorized and URL has userId, fall back to that
    if (response.status === 401 && url.includes('userId=')) {
      console.log('Auth failed, falling back to userId param');
      return publicFetch(url, options);
    }

    return response;
  } catch (error) {
    console.error('authenticatedFetch error:', error);
    
    // Final fallback - if URL has userId, try that
    if (url.includes('userId=')) {
      return publicFetch(url, options);
    }
    
    throw error;
  }
};

export const publicFetch = async (url, options = {}) => {
  //console.log('[DBG4 - publicFetch] Starting fetch to:', url, 'with options:', {
  //  ...options,
  //  body: options.body ? JSON.parse(options.body) : undefined
  //});

  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {})
      }
    });

    if (!response.ok) {
      let errorDetails = '';
      try {
        const errorJson = await response.json();
        errorDetails = JSON.stringify(errorJson);
        console.log('[DBG4 - publicFetch] Error response JSON:', errorJson);
      } catch (e) {
        errorDetails = await response.text();
        console.log('[DBG4 - publicFetch] Error response text:', errorDetails);
      }

      console.log('[DBG4 - publicFetch] Response not OK:', {
        status: response.status,
        statusText: response.statusText,
        errorDetails,
        headers: Object.fromEntries(response.headers.entries())
      });

      throw new Error(`HTTP error! status: ${response.status} - ${errorDetails}`);
    }

    return response;
  } catch (error) {
    console.log('[DBG4 - publicFetch] Caught fetch error:', error, 'Stack:', error.stack);
    throw new Error(`Failed to fetch: ${error.message}`);
  }
};