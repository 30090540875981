import React, { useRef, useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import * as MuiIcons from '@mui/icons-material';
import AddSymbolButton from './AddSymbolButton';
import { useCategories } from '../services/api';
import { usePopper } from 'react-popper';
import SettingsDialog from './SettingsDialog';
import { FIELD_NAMES, DEFAULT_COLOR_SETTINGS } from '../config/constants';
import FilterHeaderIcons from './FilterHeaderIcons';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import AIFilterModal from './AIFilterModal';

const useClickOutside = (ref, popperRef, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const isInsideContainer = ref.current && ref.current.contains(event.target);
      const isInsidePopper = popperRef.current && popperRef.current.contains(event.target);
      
      if (isInsideContainer || isInsidePopper) {
        return;
      }
      
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, popperRef, handler]);
};

const FilterDropdowns = ({ 
  selectedTab, 
  onSelectTab, 
  onAddSymbol, 
  loggedInUser,
  existingSymbols, 
  activeFilters,
  onFilterApply, 
  watchlist,
  userSettings,
  onModalOpen,
  onModalContentSet,
  onSuccess,
  onDeleteList,
  userListsData
}) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [activeSettingsCategory, setActiveSettingsCategory] = useState(null);
  const [colorSettings, setColorSettings] = useState(() => {
    const savedSettings = localStorage.getItem('colorSettings');
    return savedSettings ? JSON.parse(savedSettings) : DEFAULT_COLOR_SETTINGS;
  });
  const { data: categories, isLoading, error } = useCategories();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  console.log('FilterDropdowns received userListsData:', userListsData);
  const userLists = userListsData || [];
  console.log('Processed userLists:', userLists);

  const handleSettingsClick = (categoryId) => {
    setActiveSettingsCategory(categoryId);
    setIsSettingsOpen(true);
  };

  const handleSettingsSave = (newSettings) => {
    const validSettings = newSettings.filter(setting => 
      setting.conditions.every(condition => FIELD_NAMES.includes(condition.field))
    );
    setColorSettings(validSettings);
    localStorage.setItem('colorSettings', JSON.stringify(validSettings));
    setIsSettingsOpen(false);
  };

  const handleDropdownToggle = (categoryId) => {
    setActiveDropdown(current => current === categoryId ? null : categoryId);
  };

  const handleFilterClick = (filterId, event) => {
    if (filterId === 'ai_filter') {
      onModalContentSet({
        content: <AIFilterModal 
          referenceElement={event.currentTarget}
          onClose={() => onModalOpen(false)} 
          onSuccess={onSuccess} 
        />
      });
      onModalOpen(true);
    } else {
      if (selectedTab !== filterId) {
        onSelectTab(filterId);
      }
      handleDropdownToggle(null);
    }
  };

  const isListInCategory = useCallback((list, categoryId) => {
    if (list.categories) {
      return list.categories.includes(categoryId);
    }
    return list.category_id === categoryId;
  }, []);

  if (isLoading) {
    return (
      <div className="flex gap-2">
        {[1, 2, 3].map(i => (
          <div key={i} className="animate-pulse h-10 w-32 bg-gray-200 rounded-lg" />
        ))}
      </div>
    );
  }

  if (error) {
    console.error('Error loading categories:', error);
    return <div className="text-red-500">Failed to load categories</div>;
  }

  return (
    <>
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex flex-wrap items-center gap-2">
          {categories?.map((category) => (
            <div key={category.id} className="flex items-center">
              <FilterDropdown
                category={category}
                selectedTab={selectedTab}
                onSelectTab={onSelectTab}
                onAddSymbol={onAddSymbol}
                loggedInUser={loggedInUser}
                existingSymbols={existingSymbols}
                isOpen={activeDropdown === category.id}
                onToggle={handleDropdownToggle}
                watchlist={watchlist}
                isListInCategory={isListInCategory}
                userLists={userLists}
                onModalOpen={onModalOpen}
                onModalContentSet={onModalContentSet}
                onSuccess={onSuccess}
                onDeleteList={onDeleteList}
              />
              {category.id === 'personal' && (
                <>
                  <AddSymbolButton
                    onAddSymbol={onAddSymbol}
                    existingSymbols={existingSymbols}
                    isLoggedIn={!!loggedInUser?.email}
                    className="ml-2 p-2 rounded-lg bg-gray-100 text-gray-600 hover:bg-gray-200 focus:outline-none"
                    data-tooltip="Add symbol to Watch List"
                    aria-label="Add symbol to Watch List"
                    usePortal={true}
                  >
                    +
                  </AddSymbolButton>
                </>
              )}
            </div>
          ))}
        </div>

        <div className="flex items-center ml-auto">
          <FilterHeaderIcons 
            activeFilters={activeFilters}
            onApplyFilters={onFilterApply}
            onFilterClick={() => setIsFilterOpen(true)}
          />
        </div>
      </div>

      <SettingsDialog
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        onSave={handleSettingsSave}
        initialSettings={colorSettings}
      />

      <Tooltip 
        id="tooltip" 
        place="right" 
        offset={5}
        className="z-[9999]"
      />
    </>
  );
};

const IconComponent = ({ iconName }) => {
  const cleanIconName = iconName?.startsWith('fa-') ? iconName.slice(3) : iconName;
   
  // Special cases mapping for AI-related icons
  const specialIcons = {
    'magic': 'AutoAwesome',
    'trash': 'Delete'
  };

  // Use special mapping if it exists, otherwise use the standard formatting
  const formattedName = specialIcons[cleanIconName] || cleanIconName
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
     
  const IconElement = MuiIcons[formattedName];
   
  return (
    <span className="category-icon">
      {IconElement ? <IconElement /> : <MuiIcons.HelpOutline />}
    </span>
  );
};

const FilterDropdown = ({ 
  category, 
  selectedTab, 
  onSelectTab,
  onAddSymbol,
  loggedInUser,
  existingSymbols,
  isOpen,
  onToggle,
  watchlist,
  isListInCategory,
  userLists,
  onModalOpen,
  onModalContentSet,
  onSuccess,
  onDeleteList
}) => {
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  useClickOutside(containerRef, { current: popperElement }, () => {
    if (isOpen) {
      onToggle(null);
    }
  });

  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4], // [x, y] offset
        },
      },
    ],
  });

  const getIconName = useCallback((iconString) => {
    return iconString?.startsWith('fa-') ? iconString.slice(3) : iconString;
  }, []);

  const isPersonalCategory = category.id === 'personal';
  
  const filters = isPersonalCategory 
    ? [
        { id: 'watchlist', name: 'Watch List', icon: 'star' },
        { id: 'ai_filter', name: 'Generate New List with AI', icon: 'magic' },
        ...(Array.isArray(userLists) && userLists.length > 0 
          ? userLists.map(list => {
            if (!list?.id) {
              console.warn('Invalid list found:', list);
              return null;
            }
            return {
              id: list.id,
              name: list.name || 'Unnamed List',
              icon: list.type === 'ai_filter' ? 'magic' : 'list',
              description: list.description,
              tooltip: list.tooltip,
              type: list.type,
              symbols: list.symbols || []
            };
          }).filter(Boolean)  // Remove any null entries
          : []),
        ...(category.filters || [])
      ]
    : category.filters || [];

  console.log('Final filters array:', filters);

  const selectedFilter = filters.find(f => f.id === selectedTab);

  const isSelected = isPersonalCategory 
    ? selectedTab === 'watchlist' || 
      userLists?.some(list => list.id === selectedTab) ||
      category.filters?.some(f => f.id === selectedTab)
    : selectedTab === category.id || category.filters?.some(f => f.id === selectedTab);

  const updateDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
    }
  }, [isOpen]);

  //console.log('Category prop received:', category);

  const [showAIFilter, setShowAIFilter] = useState(false);

  const handleFilterClick = (filterId, event) => {
    if (filterId === 'ai_filter') {
      setShowAIFilter(true);
    } else {
      if (selectedTab !== filterId) {
        onSelectTab(filterId);
      }
      onToggle(null);
    }
  };

  // Separate AI Filter from regular filters
  const regularFilters = filters.filter(f => f.id !== 'ai_filter');
  const aiFilter = filters.find(f => f.id === 'ai_filter');

  const [isAIFilterExpanded, setIsAIFilterExpanded] = useState(false);

  return (
    <>
      <div className="inline-flex relative" ref={containerRef}>
        <button
          ref={setReferenceElement}
          onClick={() => onToggle(category.id)}
          className={`
            filter-button px-4 py-2 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200
            ${isSelected
              ? 'bg-blue-500 text-white shadow-blue-200' 
              : 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50'}
          `}
          data-tooltip-id="tooltip"
          data-tooltip-content={category.description}
          data-tooltip-place="top"
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <IconComponent iconName={category.icon} className="mr-2" />
              <span className="dropdown-text">{category.name}</span>
            </div>
            <div className="flex items-center gap-2">
              <MuiIcons.ExpandMore className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
            </div>
          </div>
          {isSelected && selectedFilter && (
            <div className="text-sm opacity-75 mt-1 flex items-center">
              <IconComponent iconName={selectedFilter.icon} className="mr-2" />
              <span>{selectedFilter.name}</span>
            </div>
          )}
        </button>

        {isOpen && createPortal(
          <div 
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="w-56 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-50 transform-gpu"
          >
            <div className="py-1">
              {regularFilters
                .filter(filter => filter.id === 'watchlist')
                .map((filter) => (
                  <div
                    key={filter.id}
                    className={`
                      flex items-center justify-between w-full
                      ${selectedTab === filter.id ? 'bg-blue-100' : ''}
                      hover:bg-gradient-to-r from-blue-50 to-white
                      border-l-4 hover:border-blue-500 border-transparent
                      transition-all duration-200
                      group
                    `}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={filter.tooltip || filter.description}
                  >
                    <button
                      onClick={(e) => handleFilterClick(filter.id, e)}
                      className={`
                        flex items-center w-full px-4 py-2 text-sm text-left
                        ${selectedTab === filter.id 
                          ? 'text-blue-700 font-bold' 
                          : 'text-gray-700 font-bold hover:text-blue-600'}
                        transition-colors duration-200
                      `}
                    >
                      <span className="category-icon mr-6 text-blue-500">
                        <IconComponent iconName={filter.icon} />
                      </span>
                      <span>{filter.name}</span>
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onModalContentSet({
                          title: 'Add To Watch List',
                          content: <AddSymbolButton
                            onAddSymbol={onAddSymbol}
                            existingSymbols={existingSymbols}
                            isLoggedIn={!!loggedInUser?.email}
                            className="w-full"
                            usePortal={false}
                          />
                        });
                        onModalOpen(true);
                      }}
                      className="px-3 py-2 text-gray-400 hover:text-blue-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      title="Add symbol to Watch List"
                    >
                      <IconComponent iconName="plus" />
                    </button>
                  </div>
                ))}

              {category.id === 'personal' && (
                <div className="border-y border-gray-200">
                  <button
                    onClick={() => setIsAIFilterExpanded(!isAIFilterExpanded)}
                    className="ai-filter-menu-item group"
                  >
                    <div className="flex items-center">
                      <span className="ai-icon">
                        <IconComponent iconName="magic" />
                      </span>
                      <span className="font-medium">Generate list with AI</span>
                    </div>
                    <MuiIcons.ExpandMore 
                      className={`transition-transform duration-200 text-gray-400 group-hover:text-blue-500
                        ${isAIFilterExpanded ? 'rotate-180' : ''}`}
                    />
                  </button>
                  {isAIFilterExpanded && (
                    <div className="px-4 py-2 border-t border-gray-100">
                      <AIFilterModal 
                        onClose={() => onToggle(null)}
                        onSuccess={(newList) => {
                          onSuccess(newList);
                          onToggle(null);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              
              {regularFilters
                .filter(filter => filter.id !== 'watchlist')
                .map((filter) => (
                  <div
                    key={filter.id}
                    className={`
                      flex items-center justify-between w-full
                      ${filter.type === 'ai_filter' ? 'ai-filter-list' : 'dropdown-menu-item'}
                      ${selectedTab === filter.id ? 'bg-blue-50' : ''}
                    `}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={filter.tooltip || filter.description}
                  >
                    <button
                      onClick={(e) => handleFilterClick(filter.id, e)}
                      className={`
                        flex items-center flex-grow px-4 py-2 text-sm text-left
                        ${selectedTab === filter.id ? 'text-blue-700' : 'text-gray-700 hover:bg-gray-50'}
                      `}
                    >
                      <span className="category-icon mr-6">
                        <IconComponent iconName={filter.icon} />
                      </span>
                      <span>{filter.name}</span>
                    </button>
                    {filter.type === 'ai_filter' && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteList(filter.id);
                        }}
                        className="px-3 py-2 text-gray-400 hover:text-red-500"
                        title="Delete list"
                      >
                        <IconComponent iconName="trash" />
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>,
          document.body
        )}
      </div>
    </>
  );
};

FilterDropdowns.propTypes = {
  selectedTab: PropTypes.string,
  onSelectTab: PropTypes.func.isRequired,
  onAddSymbol: PropTypes.func.isRequired,
  loggedInUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  existingSymbols: PropTypes.array,
  activeFilters: PropTypes.object,
  onFilterApply: PropTypes.func.isRequired,
  watchlist: PropTypes.array,
  userSettings: PropTypes.object,
  onModalOpen: PropTypes.func.isRequired,
  onModalContentSet: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired
};

FilterDropdown.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    filters: PropTypes.array,
    description: PropTypes.string
  }).isRequired,
  selectedTab: PropTypes.string,
  onSelectTab: PropTypes.func.isRequired,
  onAddSymbol: PropTypes.func,
  loggedInUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  existingSymbols: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  watchlist: PropTypes.array,
  userLists: PropTypes.array,
  onModalOpen: PropTypes.func.isRequired,
  onModalContentSet: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired
};

export default FilterDropdowns; 