import { jwtDecode } from 'jwt-decode';
import CONFIG from '../config/config';

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Token decode error:', error);
    return true;
  }
};

export const checkAndRefreshToken = async () => {
  const token = localStorage.getItem('authToken');
  if (!token) return false;

  // Only refresh if token is actually expired or close to expiring
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const timeUntilExpiry = decoded.exp - currentTime;
    
    // If token is still valid for more than an hour, don't refresh
    if (timeUntilExpiry > 3600) {
      return true;
    }

    console.log('Token needs refresh, time until expiry:', timeUntilExpiry);
    
    const response = await fetch(`${CONFIG.API_URLS.AUTH.REFRESH_TOKEN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      console.error('Token refresh failed:', await response.text());
      localStorage.removeItem('authToken');
      return false;
    }

    const { token: newToken } = await response.json();
    localStorage.setItem('authToken', newToken);
    return true;
  } catch (error) {
    console.error('Token refresh failed:', error);
    return false;
  }
}; 