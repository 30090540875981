import { ALL_COLUMNS } from './columnConfig'; // Added import

export const DEFAULT_COLOR_SETTINGS = [
  {
    conditions: [
      { field: 'trafficChange', operator: '>', value: '0' },
      { field: 'stockChange', operator: '<=', value: '0' },
      { field: 'outlook', operator: '>', value: '0.7' }
    ],
    color: '#e6ffe6' // Light green
  },
  {
    conditions: [
      { field: 'trafficChange', operator: '<', value: '0' },
      { field: 'stockChange', operator: '>=', value: '0' }
    ],
    color: '#ffe6e6' // Light red
  },
  {
    conditions: [], // Empty conditions array will match all rows
    color: 'transparent' // Default background for rows that don't match any other rules
  }
];

// Replace SORT_COLUMNS with ALL_COLUMNS
export const FIELD_NAMES = ALL_COLUMNS.map(column => column.key);
