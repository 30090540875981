import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import './GoogleLoginButton.css';

const CLIENT_ID = '868076104604-rr5baroe3tcrgvfjepp5nauo9gp3s0f2.apps.googleusercontent.com'; 

const GoogleLoginButton = ({ onLoginSuccess, onLoginFailure }) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <GoogleLogin
        onSuccess={onLoginSuccess}
        onError={onLoginFailure}
        useOneTap
        className="google-login-button"
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
