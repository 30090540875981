import React, { useState, useMemo } from 'react';
import { FiChevronDown, FiChevronUp, FiAlertTriangle, FiTarget, FiTrendingUp, FiTrendingDown } from 'react-icons/fi';
import DisclaimerMessage from './DisclaimerMessage';
import '../styles/MacroScenarios.css';

const ScenarioCard = ({ scenario }) => (
  <div className="scenario-card">
    <div className="scenario-header">
      <h4 className="scenario-title">{scenario.title}</h4>
      <span className={`probability-badge ${scenario.probability >= 50 ? 'high' : 'low'}`}>
        {scenario.probability}% Probability
      </span>
    </div>
    
    <p className="scenario-description">{scenario.description}</p>
    
    <div className="scenario-details">
      <div className="risks-section">
        <h5><FiAlertTriangle className="icon-warning" /> Key Risks</h5>
        <ul>
          {scenario.risks.map((risk, index) => (
            <li key={index}>{risk}</li>
          ))}
        </ul>
      </div>
      
      <div className="opportunities-section">
        <h5><FiTarget className="icon-success" /> Opportunities</h5>
        <ul>
          {scenario.opportunities.map((opportunity, index) => (
            <li key={index}>{opportunity}</li>
          ))}
        </ul>
      </div>
    </div>
    
    <div className="sector-implications">
      <div className="potential-winners">
        <h5><FiTrendingUp className="icon-green" /> Potential Winners</h5>
        {scenario.sectorImplications.winners.join(', ')}
      </div>
      <div className="potential-losers">
        <h5><FiTrendingDown className="icon-red" /> Potential Challenges</h5>
        {scenario.sectorImplications.challenged.join(', ')}
      </div>
    </div>
  </div>
);

const CompactSummary = ({ scenarios }) => {
  // Calculate total probability to ensure it adds up to 100%
  const totalProb = scenarios.reduce((sum, s) => sum + s.probability, 0);
  
  // Get top 2-3 scenarios by probability
  const topScenarios = [...scenarios]
    .sort((a, b) => b.probability - a.probability)
    .slice(0, 3);

  return (
    <div className="compact-summary">
      <div className="scenario-pills">
        {topScenarios.map((scenario, index) => (
          <div 
            key={index} 
            className={`scenario-pill ${scenario.probability > 40 ? 'high-prob' : 'medium-prob'}`}
          >
            <span className="scenario-name">{scenario.title}</span>
            <span className="probability">{Math.round(scenario.probability)}%</span>
          </div>
        ))}
      </div>
      <div className="sector-summary">
        <div className="sector-group">
          <FiTrendingUp className="icon-green" />
          <span>Leading: </span>
          {Array.from(new Set(topScenarios.flatMap(s => s.sectorImplications.winners)))
            .slice(0, 3)
            .join(', ')}
        </div>
        <div className="sector-group">
          <FiTrendingDown className="icon-red" />
          <span>Lagging: </span>
          {Array.from(new Set(topScenarios.flatMap(s => s.sectorImplications.challenged)))
            .slice(0, 3)
            .join(', ')}
        </div>
      </div>
    </div>
  );
};

const MacroScenariosSection = ({ scenarios, isLoading, error, lastUpdated }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const validScenarios = useMemo(() => {
    if (!Array.isArray(scenarios)) return [];
    return scenarios.filter(scenario => (
      scenario.title && 
      typeof scenario.probability === 'number' &&
      scenario.description &&
      Array.isArray(scenario.risks) &&
      Array.isArray(scenario.opportunities) &&
      scenario.sectorImplications?.winners &&
      scenario.sectorImplications?.challenged
    ));
  }, [scenarios]);

  if (error) {
    return (
      <div className="error-message">
        <FiAlertTriangle /> Error loading macro scenarios: {error.message}
      </div>
    );
  }

  if (isLoading) {
    return <div className="loading">Loading macro scenarios...</div>;
  }

  if (validScenarios.length === 0) {
    return (
      <div className="warning-message">
        <FiAlertTriangle /> No valid scenarios available
      </div>
    );
  }

  return (
    <div className="macro-scenarios-section">
      <div className="section-header">
        <div className="header-content" onClick={() => setIsExpanded(!isExpanded)}>
          <h3>Macro Scenarios Analysis</h3>
          {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
        </div>
        <CompactSummary scenarios={validScenarios} />
      </div>

      {isExpanded && (
        <div className="scenarios-content">
          <div className="scenarios-grid">
            {validScenarios.map((scenario, index) => (
              <ScenarioCard key={index} scenario={scenario} />
            ))}
          </div>
          
          <DisclaimerMessage 
            message="Macro scenarios are AI-generated based on current market conditions and historical patterns. Use as one of many inputs in your research process."
            lastUpdated={lastUpdated}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default MacroScenariosSection; 