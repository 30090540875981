import React, { useCallback, useState, useEffect } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './EconomicEventControls.css';

const EconomicEventControls = ({ 
  userSettings = {},
  onSettingsChange,
  showEconomicEvents,
  setShowEconomicEvents,
  analysis
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Debug logs
  useEffect(() => {
    console.log('EconomicEventControls:', {
      hasAnalysis: !!analysis,
      analysisContent: analysis,
      isExpanded,
      showEconomicEvents
    });
  }, [analysis, isExpanded, showEconomicEvents]);

  const toggleExpansion = useCallback((e) => {
    e.stopPropagation();
    console.log('Toggle clicked, has analysis:', !!analysis);
    if (analysis) {
      setIsExpanded(prev => !prev);
    }
  }, [analysis]);

  const { 
    economicEvents = {
      selectedTimeframes: [0],
      geography: 'US',
      showEvents: true
    }
  } = userSettings;

  // Log initial props
  console.log('EconomicEventControls props:', {
    userSettings,
    showEconomicEvents
  });

  const handleTimeframeChange = useCallback((value) => {
    console.log('handleTimeframeChange called with:', value);
    
    // Get current timeframes with proper default
    const currentTimeframes = economicEvents?.selectedTimeframes || [0];
    console.log('Current timeframes:', currentTimeframes);
    
    // Toggle the selected timeframe - allow complete deselection
    const newTimeframes = currentTimeframes.includes(value)
      ? currentTimeframes.filter(t => t !== value)
      : [...currentTimeframes, value];

    console.log('New timeframes:', newTimeframes);

    // Update settings
    onSettingsChange('economicEvents', {
      ...economicEvents,
      selectedTimeframes: newTimeframes,
      showEvents: newTimeframes.length > 0 // Only show events if timeframes are selected
    });

    // Update visibility based on whether any timeframes are selected
    setShowEconomicEvents(newTimeframes.length > 0);
  }, [economicEvents, onSettingsChange, setShowEconomicEvents]);

  const handleGeographyChange = useCallback((e) => {
    console.log('handleGeographyChange called with:', e.target.value);
    onSettingsChange('economicEvents', {
      ...economicEvents,
      geography: e.target.value
    });
  }, [economicEvents, onSettingsChange]);

  // Log current state
  console.log('Current economicEvents state:', economicEvents);

  const renderSentimentMeter = (compact = false) => {
    if (!analysis?.sentiment_score) return null;
    
    return (
      <div className={`sentiment-meter-container ${compact ? 'compact' : ''}`}>
        <div className="sentiment-label">
          <span className="text-sm text-gray-600">Sentiment:</span>
          <span className={`text-sm font-medium ${
            analysis.sentiment_score > 0.6 ? 'text-green-600' :
            analysis.sentiment_score < 0.4 ? 'text-red-600' :
            'text-gray-600'
          }`}>
            {(analysis.sentiment_score * 100).toFixed(0)}%
          </span>
        </div>
        <div className="sentiment-meter">
          <div 
            className="sentiment-fill"
            style={{
              width: `${analysis.sentiment_score * 100}%`,
              backgroundColor: `${
                analysis.sentiment_score > 0.6 ? '#10B981' :
                analysis.sentiment_score < 0.4 ? '#EF4444' :
                '#6B7280'
              }`
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-100 border-t border-gray-200">
      <div className="py-2 px-4 flex items-center justify-between">
        {/* Left side - Events controls and Geography */}
        <div className="flex items-center space-x-4">
          <span className="font-medium text-sm">Economic Events:</span>
          <div className="flex items-center space-x-4">
            {[-1, 0, 1].map((value) => (
              <button
                key={value}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTimeframeChange(value);
                }}
                className={`px-2 py-1 rounded text-xs ${
                  economicEvents?.selectedTimeframes?.includes(value)
                    ? 'bg-blue-500 text-white' 
                    : 'bg-white text-gray-700 border border-gray-300'
                } hover:bg-blue-100`}
              >
                {value === -1 ? 'Last Week' : value === 0 ? 'This Week' : 'Next Week'}
              </button>
            ))}
          </div>

          {/* Geography moved here - temporarily hidden
          <div className="flex items-center geography-select-container">
            <span className="mr-2 font-medium text-sm">Geography:</span>
            <select
              value={userSettings?.economicEvents?.geography || 'US'}
              onChange={handleGeographyChange}
              className="p-1 border rounded bg-white text-xs"
            >
              {['US', 'EU', 'UK', 'JP', 'CN'].map((country) => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </div>
          */}
        </div>

        {/* Right side - Sentiment and Expand only */}
        <div className="flex items-center space-x-4">
          {/* Compact Sentiment Meter */}
          {analysis && !isExpanded && renderSentimentMeter(true)}

          {/* Expansion Control */}
          {analysis && (
            <button
              onClick={toggleExpansion}
              className="flex items-center space-x-2 px-3 py-1 rounded-md hover:bg-gray-200 transition-colors"
            >
              <span className="text-sm font-medium text-gray-700">
                {isExpanded ? 'Hide Analysis' : 'Show Analysis'}
              </span>
              <div className="text-gray-500">
                {isExpanded ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
              </div>
            </button>
          )}
        </div>
      </div>

      {/* Analysis Panel */}
      {analysis && (
        <div 
          className={`analysis-panel transition-all duration-300 ease-in-out`}
          style={{
            maxHeight: isExpanded ? '800px' : '0',
            opacity: isExpanded ? 1 : 0,
            pointerEvents: isExpanded ? 'auto' : 'none',
            visibility: isExpanded ? 'visible' : 'hidden'
          }}
        >
          <div className="analysis-content">
            <div className="analysis-grid">
              <div className="analysis-main">
                <h3 className="text-lg font-semibold text-gray-900 mb-3">Economic Indicators Analysis</h3>
                <p className="text-gray-700 leading-relaxed">{analysis.analysis}</p>
                
                {analysis.key_implications && (
                  <div className="mt-4">
                    <h4 className="text-md font-semibold text-gray-800 mb-2">Key Implications</h4>
                    <ul className="space-y-2">
                      {analysis.key_implications.split('. ').map((implication, index) => (
                        <li key={index} className="flex items-start">
                          <span className="text-blue-500 mr-2">•</span>
                          <span className="text-gray-600">{implication}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="analysis-sidebar">
                <div className="impact-card">
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Market Impact</h4>
                  <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                    analysis.market_impact === 'Bullish' ? 'bg-green-100 text-green-800' :
                    analysis.market_impact === 'Bearish' ? 'bg-red-100 text-red-800' :
                    'bg-gray-100 text-gray-800'
                  }`}>
                    {analysis.market_impact}
                  </div>
                </div>

                <div className="impact-card">
                  <h4 className="text-sm font-medium text-gray-500 mb-2">Sentiment Analysis</h4>
                  <div className="flex items-baseline justify-between mb-1">
                    <span className="text-2xl font-bold text-gray-900">
                      {(analysis.sentiment_score * 100).toFixed(0)}%
                    </span>
                    <span className="text-sm text-gray-500">
                      {analysis.sentiment_score > 0.6 ? 'Positive' : 
                       analysis.sentiment_score < 0.4 ? 'Negative' : 'Neutral'}
                    </span>
                  </div>
                  <div className="sentiment-meter">
                    <div 
                      className="sentiment-fill"
                      style={{
                        width: `${analysis.sentiment_score * 100}%`,
                        backgroundColor: `${
                          analysis.sentiment_score > 0.6 ? '#10B981' :
                          analysis.sentiment_score < 0.4 ? '#EF4444' :
                          '#6B7280'
                        }`
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(EconomicEventControls);